import { useTimeframeSwitcherState } from 'common/components/TimeframeSwitcher/useTimeframeSwitcherState';
import { darkBlue, darkNavy, lightGreyBackground } from 'common/styles/colors';
import { useDaySelector } from 'features/pick-schedule-views/hooks/useDaySelector';
import { FC, useEffect, useRef } from 'react';
import styled from 'styled-components';

interface Props {
  date: string;
}

const Wrapper = styled.div`
  background-color: ${lightGreyBackground};
  margin: 0.5rem 0;
  width: 100%;
  display: inline-grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  flex-direction: row;
  justify-content: space-between;

  button {
    border: none;
    background-color: transparent;
    padding: 0.75rem 0.25rem;

    p {
      margin: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: ${darkBlue};
    }
  }

  .selected {
    color: ${darkNavy};
    border-bottom: solid 4px ${darkNavy};
  }
`;

const weekDays: string[] = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

export const DaySelector: FC<Props> = ({ date }) => {
  const { offset, setOffset } = useDaySelector();
  const isFirstRender = useRef<boolean>(true);
  const { state } = useTimeframeSwitcherState('pick-schedule', 'week');

  // Select Monday on week changes
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    setOffset(0);
  }, [setOffset, state.offset]);

  return (
    <Wrapper>
      {weekDays.map((day, index) => (
        <button key={day} type='button' onClick={() => setOffset(index)}>
          {offset.value === index ? (
            <p className='selected'>
              {day} &bull; {date}
            </p>
          ) : (
            <p>{day}</p>
          )}
        </button>
      ))}
    </Wrapper>
  );
};
