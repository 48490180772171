import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export const switcherSliceName = 'timeframeSwitcherSlice';

export interface SwitcherSliceState {
  [key: string]: number;
}

export interface SwitcherAction {
  key: string;
  newOffset: number;
}

export const timeframeSwitcherSlice = createSlice({
  name: switcherSliceName,
  initialState: {} as SwitcherSliceState,
  reducers: {
    setOffset: (
      state: SwitcherSliceState,
      action: PayloadAction<SwitcherAction>,
    ) => {
      const { key, newOffset } = action.payload;
      state[key] = newOffset;
    },
  },
});
