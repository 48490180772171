import { handleError } from 'common/api/handleError';
import { useGetPackHousesQuery } from 'common/api/packHouse.api';
import { useGetVarietiesQuery } from 'common/api/varietyApi';
import {
  CsvButton,
  CsvHeaders,
  emptyCellValue,
} from 'common/components/CsvButton/CsvButton';
import { WithLoadingOverlay } from 'common/components/LoadingSpinner';
import { TimeframeSwitcher } from 'common/components/TimeframeSwitcher/TimeframeSwitcher';
import { useTimeframeSwitcherState } from 'common/components/TimeframeSwitcher/useTimeframeSwitcherState';
import { Variety } from 'common/models';
import { Size } from 'common/models/growerBlock/size';
import { PackHouse } from 'common/models/packHouse';
import {
  CsvComboRows,
  CsvPlanHeaderKeys,
} from 'features/pack-plan-views/utils/prepareCsvPlanData';
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { Constants } from 'utils/constants';
import { generateWeekEndString } from 'utils/helperFunction';
import { EditPackPlanModal } from '../../components/PackPlanTable/modals/EditPackPlanModal';
import { PackPlanTable } from '../../components/PackPlanTable/PackPlanTable';
import { Container, Header, Section, SwitcherCsvBtn, Title } from './styles';

export type ReqToSchedule = {
  packHouse: PackHouse;
  variety: Variety;
  market: string;
  size: Size;
  date: string;
  bins: number;
  binId: number;
};

type PlanViewProps = {
  toggleModal: Dispatch<SetStateAction<boolean>>;
  weekStartDate: Date;
  setReqToSchedule: Dispatch<
    SetStateAction<ReqToSchedule | Record<string, never>>
  >;
  isSavingReq: boolean;
  setIsSavingReq: Dispatch<SetStateAction<boolean>>;
  setLoadingPlanCount: React.Dispatch<React.SetStateAction<number>>;
  redirectToSchedule: (binReq: ReqToSchedule | Record<string, never>) => void;
};

export const PlanViewContext = React.createContext<PlanViewProps>(null!);

export const PackPlanView: FC = () => {
  const {
    data: packHouses,
    isLoading,
    error: fetchPHError,
  } = useGetPackHousesQuery();
  const {
    data: varieties,
    isLoading: isLoadingVarieties,
    error: varietyError,
  } = useGetVarietiesQuery();
  const history = useHistory();
  const { state: week } = useTimeframeSwitcherState('pack-plan', 'week');

  const [isModalOpen, toggleModal] = useState(false);
  const [reqToSchedule, setReqToSchedule] = useState<
    ReqToSchedule | Record<string, never>
  >({});
  const [isSavingReq, setIsSavingReq] = useState(false);

  const [csvPlanData, setCsvPlanData] = useState({
    headers: [] as CsvHeaders<CsvPlanHeaderKeys>[],
    rows: {} as Record<string, CsvComboRows>,
  });
  const [formattedCsvRows, setFormattedCsvRows] = useState([] as CsvComboRows);
  const [loadingPlanCount, setLoadingPlanCount] = useState(0);

  const formatCsvRows = () => {
    const emptyRow = {
      [`${CsvPlanHeaderKeys.pHouseCode}`]: emptyCellValue,
      [`${CsvPlanHeaderKeys.variety}`]: emptyCellValue,
      [`${CsvPlanHeaderKeys.market}`]: emptyCellValue,
      [`${CsvPlanHeaderKeys.size}`]: emptyCellValue,
      [`${CsvPlanHeaderKeys.day}`]: emptyCellValue,
      [`${CsvPlanHeaderKeys.totalRequested}`]: emptyCellValue,
      [`${CsvPlanHeaderKeys.totalPicked}`]: emptyCellValue,
    };
    let newRows = [] as CsvComboRows;
    const arrRows = Object.keys(csvPlanData.rows)
      .sort((a, b) => a.localeCompare(b))
      .map(key => {
        const houseRows = csvPlanData.rows[`${key}`];
        if (
          !houseRows.findLast(
            element =>
              element[`${CsvPlanHeaderKeys.pHouseCode}`] === emptyCellValue,
          )
        ) {
          houseRows.push(emptyRow);
        }
        return houseRows;
      });
    arrRows.forEach(arr => {
      newRows = [...newRows.concat(arr)];
    });
    setFormattedCsvRows(newRows);
  };

  const redirectToSchedule = (binReq: ReqToSchedule | Record<string, never>) =>
    history.push({
      pathname: `${Constants.routes.PICK_SCHEDULE}${Constants.routes.NEW_PICK_LIST}`,
      state: binReq,
    });

  useEffect(() => {
    if (fetchPHError) handleError(fetchPHError, 'Unable to load pack houses.');
    if (varietyError) handleError(varietyError, 'Unable to load varieties.');
  }, [fetchPHError, varietyError]);

  return (
    <PlanViewContext.Provider
      value={{
        toggleModal,
        weekStartDate: week.startDate,
        setReqToSchedule,
        isSavingReq,
        setIsSavingReq,
        redirectToSchedule,
        setLoadingPlanCount,
      }}
    >
      <WithLoadingOverlay isLoading={isLoading}>
        <Container>
          <Header>
            <Title>Pack Plan</Title>
            <SwitcherCsvBtn>
              <TimeframeSwitcher identifier='pack-plan' timeframe='week' />
              <CsvButton
                headers={csvPlanData.headers}
                data={formattedCsvRows}
                filename={`${generateWeekEndString(
                  week.weekdays[6],
                )}_pack_plan`}
                onClick={() => {
                  // Stop component logic if any pack plan data is being retrieved
                  if (loadingPlanCount || isSavingReq) return false;
                  formatCsvRows();
                  return true;
                }}
                isLoading={!!loadingPlanCount || isSavingReq}
              />
            </SwitcherCsvBtn>
          </Header>
          <Section>
            {packHouses?.map(house => (
              <PackPlanTable
                key={house.id}
                packHouse={house}
                varieties={varieties}
                isLoadingVarieties={isLoadingVarieties}
                week={week}
                setCsvPlanData={setCsvPlanData}
              />
            ))}
          </Section>
        </Container>
      </WithLoadingOverlay>
      <EditPackPlanModal
        redirect={() => redirectToSchedule(reqToSchedule)}
        isOpen={isModalOpen}
        closeModal={() => toggleModal(false)}
      />
    </PlanViewContext.Provider>
  );
};
