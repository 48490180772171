import { mobile } from 'common/styles/breakpoints';
import { darkOrange, orange, white } from 'common/styles/colors';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const AddUserBtn = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  background: ${orange};
  border-radius: 5px;
  color: ${white};
  text-decoration: none;
  font-size: 15px;
  font-family: KanitSemiBold;
  padding: 2px 10px;
  margin-left: 30px;

  .add-icon {
    height: 18px;
    width: 18px;
  }

  &:hover {
    background: ${darkOrange};
  }

  @media (max-width: ${mobile}) {
    margin: 0;
  }
`;
