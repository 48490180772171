import { MainContainerWrapper } from 'common/components/MainContainerWrapper/MainContainerWrapper';
import { NotFoundView } from 'common/components/NotFound';
import { RoleType } from 'common/models';
import { PrivateRoute } from 'features/auth/components/privateRoute.tsx';
import { UserRoutes } from 'features/user-views/Routes';
import { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Constants } from 'utils/constants';
import { SpokaneSync } from './components/SpokaneSync';
import SettingsView from './SettingsView';
import { RatesView } from './components/Sub-views/RatesView/RatesView';

export const SettingsRoutes: FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute
        exact
        path={path}
        component={SettingsView}
        requiredRoles={[RoleType.Admin]}
      />
      <PrivateRoute
        exact
        path={`${path}${Constants.routes.SYNC}`}
        component={SpokaneSync}
        requiredRoles={[RoleType.Admin]}
      />
      <PrivateRoute
        exact
        path={`${path}${Constants.routes.POOL_SETTINGS}`}
        component={() => <MainContainerWrapper title='Pool Settings' />}
        requiredRoles={[RoleType.Admin]}
      />
      <PrivateRoute
        exact
        path={`${path}${Constants.routes.RATE_SETTINGS}`}
        component={RatesView}
        requiredRoles={[RoleType.Admin]}
      />
      <PrivateRoute
        exact
        path={`${path}${Constants.routes.CONTRACTOR_SETTINGS}`}
        component={() => <MainContainerWrapper title='Contractor Settings' />}
        requiredRoles={[RoleType.Admin]}
      />
      <PrivateRoute
        exact
        path={`${path}${Constants.routes.PAYROLL_SETTINGS}`}
        component={() => <MainContainerWrapper title='Payroll Settings' />}
        requiredRoles={[RoleType.Admin]}
      />
      <PrivateRoute
        path={`${path}${Constants.routes.USERS_LIST}`}
        component={UserRoutes}
        requiredRoles={[RoleType.Admin]}
      />
      <Route component={NotFoundView} />
    </Switch>
  );
};
