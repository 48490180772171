import { WizardDropdownOption } from 'common/components/WizardControls/WizardDropdown';
import { Contractor } from 'common/models';
import { Season } from 'common/models/season';

export const createContractorOptions = (
  contractors: Contractor[] | undefined,
): WizardDropdownOption<Contractor>[] =>
  contractors
    ?.filter(contractor => contractor.contractorName !== 'TBA')
    ?.map(contractor => ({
      label: contractor.code,
      value: contractor,
    }))
    ?.sort((a, b) => a.label.localeCompare(b.label)) || [];

export const createSeasonOptions = (
  seasons: Season[] | undefined,
): WizardDropdownOption<Season>[] =>
  seasons
    ?.map(season => ({
      label: `${season.year}`,
      value: season,
    }))
    ?.sort((a, b) => a.label.localeCompare(b.label)) || [];
