import { FC, ReactNode } from 'react';
import { MainContainer, MainTitle } from './styles';

/**
 * Reusable component to wrap the content of main pages.
 */
export const MainContainerWrapper: FC<{
  title: string;
  children?: ReactNode;
  maxWidth?: string;
}> = ({ title, children, maxWidth }) => (
  <MainContainer $maxWidth={maxWidth}>
    <MainTitle>{title}</MainTitle>
    {children}
  </MainContainer>
);
