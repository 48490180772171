import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { FC } from 'react';
import { SwitcherContainer } from './styles';
import {
  MonthSwitcherState,
  SwitcherIds,
  Timeframe,
  useTimeframeSwitcherState,
  WeekSwitcherState,
} from './useTimeframeSwitcherState';

export const TimeframeSwitcher: FC<{
  identifier: SwitcherIds;
  timeframe: Timeframe;
  leftProps?: React.HTMLProps<HTMLButtonElement>;
  rightProps?: React.HTMLProps<HTMLButtonElement>;
  disableButtons?: boolean;
}> = ({
  identifier,
  timeframe,
  leftProps,
  rightProps,
  disableButtons = false,
}) => {
  const { state, useDecrement, useIncrement } = useTimeframeSwitcherState(
    identifier,
    timeframe,
  );

  return (
    <SwitcherContainer>
      <button
        {...leftProps}
        type='button'
        onClick={useDecrement}
        disabled={disableButtons}
      >
        <ChevronLeftIcon />
      </button>
      {timeframe === 'week' && (
        <span>
          <p>Week:</p>
          <p>{(state as WeekSwitcherState).weekNumber}</p>
        </span>
      )}
      {timeframe === 'month' && (
        <span>
          <p>{(state as MonthSwitcherState).month}</p>
          <p>{(state as MonthSwitcherState).year}</p>
        </span>
      )}
      <button
        {...rightProps}
        type='button'
        onClick={useIncrement}
        disabled={disableButtons}
      >
        <ChevronRightIcon />
      </button>
    </SwitcherContainer>
  );
};
