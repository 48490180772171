import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { StyledLoadingButton } from 'common/components/LoadingButton';
import { SubmitButton } from 'common/styles/button';
import {
  blueShade,
  darkNavy,
  lightGreyText,
  orange,
  white,
} from 'common/styles/colors';
import { FormPadding } from 'common/styles/form';
import { boxShadow } from 'common/styles/page';
import styled from 'styled-components';
import { Constants } from 'utils/constants';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${FormPadding};
  margin: 20px;
  align-self: center;

  width: min(100vw, 500px);
  min-height: 327px;

  background: ${darkNavy};
  box-shadow: ${boxShadow};
  border-radius: 8px;

  flex: none;
  order: 1;
  flex-grow: 0;
`;

const StyledTitle = styled.h1`
  font-family: 'KanitRegular';
  font-weight: 300;
  font-size: 32px;
  line-height: 140%;
  letter-spacing: 0.0075em;

  color: ${blueShade};

  flex: none;
  order: 0;
  flex-grow: 0;
`;

const StyledText = styled.div`
  width: min(100%, 430px);
  font-family: 'KanitRegular';
  font-size: 20px;
  line-height: 110%;
  letter-spacing: 0.0075em;

  color: ${lightGreyText};

  flex: none;
`;

const StyledEmail = styled.p`
  font-family: 'KanitRegular';
  font-weight: 600;
  font-size: 20px;
  line-height: 110%;
  letter-spacing: 0.0075em;

  color: ${blueShade};

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  margin-top: 5%;
`;

const InviteSentButton = styled(StyledLoadingButton)`
  background-color: ${orange};
  color: ${white};
  margin-top: 40px;
  letter-spacing: 0.1em;
  width: 100px;
`;

export const InviteSentCard: FC<{ email: string }> = ({ email }) => {
  const history = useHistory();

  const goToUserListView = () => {
    history.push(`${Constants.routes.SETTINGS}${Constants.routes.USERS_LIST}`);
  };

  return (
    <StyledWrapper>
      <StyledTitle>Invite Sent</StyledTitle>
      <StyledText>
        An invite with a temporary password has been sent to
        <StyledEmail>{email}</StyledEmail>
        to create an account. They will be prompted to update their password.
      </StyledText>
      <InviteSentButton
        type='submit'
        as={SubmitButton}
        loading='false'
        onClick={goToUserListView}
      >
        DONE
      </InviteSentButton>
    </StyledWrapper>
  );
};
