export enum Market {
  DOM = 'Domestic',
  KJN = 'Korea/Japan',
  HBX = 'High Brix',
  HPK = 'High Pack',
  ANZ = 'Australia/NZ',
  HKR = 'Hong Kong Retail',
  FDJ = 'Field Juice',
  STR = 'Storage',
}
