import {
  darkerBlueShade,
  darkGrey,
  grey,
  lighterBlueShade,
  lighterNavy,
  tableBodyGrey,
  tableHeaderGrey,
  white,
} from 'common/styles/colors';
import { boxShadow } from 'common/styles/page';
import { SubText } from 'common/styles/texts';
import styled from 'styled-components';

export interface Props {
  isMobile: boolean;
  isExtraSmall: boolean;
}

export const SortFilterWrapper = styled.div<Props>`
  display: flex;
  position: relative;
  flex-direction: 'row';
  flex-wrap: wrap;
`;

export const BubblesContainer = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  flex-wrap: wrap;
  margin: ${props => (props.isMobile ? '0 5px' : '0')};

  & div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
  }

  & .sort-filter-prompt {
    font-family: KanitSemiBold;
    font-size: 16px;
    color: ${tableHeaderGrey};
    margin: 0;
    padding: 0 4px 5px;
    white-space: nowrap;
  }
`;

export const AddSortFilterBtn = styled.button<Props>`
  height: 32px;
  align-self: center;
  justify-self: center;
  border-radius: 20px;
  font-family: KanitSemiBold;
  font-size: 16px;
  color: ${darkerBlueShade};
  border: none;
  background: none;
  white-space: nowrap;
  padding: 0 8px 0 5px;
  margin: 0 5px 0 20px;

  & .add-icon {
    height: 18px;
    width: 18px;
    margin-bottom: 3px;
  }

  &:hover {
    background: ${darkerBlueShade};
    color: white;
  }
`;

export const SortFilterModal = styled.div`
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px 10px 14px;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 280px;
  background: ${lighterBlueShade};
  border: 1px solid ${lighterNavy};
  border-radius: 8px;
  z-index: 1;
  box-shadow: ${boxShadow};
`;

export const Title = styled.div`
  font-family: KanitSemiBold;
  font-size: 24px;
  color: ${tableBodyGrey};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  & button {
    background-color: transparent;
    border: none;
    height: 24px;
  }
`;

export const ApplyBtn = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 13px 28px;
  color: ${white};
  border: none;
  width: 253px;
  height: 40px;
  background: ${lighterNavy};
  border-radius: 20px;
`;

export const RowContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

export const Row = styled.button`
  display: flex;
  justify-content: space-between;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid ${grey};
  margin-bottom: 5px;
`;

export const SortFilterCheckboxContainer = styled.div`
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;
  margin: 0 0 5px 16px;
`;

export const Category = styled.div`
  font-family: KanitSemiBold;
  font-size: 16px;
  line-height: 138%;
  letter-spacing: 0.0075em;
  color: ${darkGrey};
  margin-left: 5px;
`;

export const TextInput = styled.input`
  height: 32px;
  box-shadow: ${boxShadow};
  border: 1px solid ${tableHeaderGrey};
  border-radius: 4px;
  padding: 2px 8px;
  margin: 0 10px;
`;

export const SubHeader = styled(SubText)`
  color: ${tableBodyGrey};
  padding-left: 16px;
`;

export const ScrollableContainer = styled.div`
  padding: 3px 10px 3px 0;
  max-height: 250px;
  overflow-y: scroll;

  /* Use the mobile scrollbar to always show a scrollbar,
    no matter the browser. */
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: ${grey};
    box-shadow: 0 0 1px ${white};
  }
`;

export const CheckboxContainer = styled.div<{ $separator: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 2px;
  padding-bottom: ${({ $separator }) => ($separator ? '8px' : '2px')};
  margin-bottom: ${({ $separator }) => ($separator ? '2px' : '0')};
  ${({ $separator }) => $separator && `border-bottom: 1px solid ${grey}`};
`;

export const Checkbox = styled.input`
  box-sizing: border-box;
  width: 22px;
  height: 22px;
  background: ${lighterNavy};
  border: 2px solid ${grey};
  border-radius: 2px;
  cursor: pointer;
`;

export const ValueComponent = styled.div`
  font-family: KanitRegular;
  font-size: 14px;
  margin-left: 8px;
  color: ${tableBodyGrey};
  cursor: pointer;
`;

export const BubbleContainer = styled.div`
  color: ${tableHeaderGrey};
  font-family: KanitSemiBold;
  font-size: 14px;
  padding: 0 2px 0 8px;
  height: 28px;
  background: ${white};
  border: 1px solid ${grey};
  border-radius: 30px;
  margin: 0 1px 3px;

  & .bubble-prop,
  .bubble-label {
    margin: 0 3px 0 0;
    font-size: 14px;
    white-space: nowrap;
    font-family: KanitLight;
  }

  & .bubble-label {
    font-family: KanitSemiBold;
  }

  & button {
    color: ${tableHeaderGrey};
    border: none;
    background-color: transparent;
  }
`;
