import { Settings } from '@mui/icons-material';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import DateRangeIcon from '@mui/icons-material/DateRange';
import GroupIcon from '@mui/icons-material/Group';
import NaturePeopleIcon from '@mui/icons-material/NaturePeople';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import SyncIcon from '@mui/icons-material/Sync';
import TuneIcon from '@mui/icons-material/Tune';
import { Constants } from 'utils/constants';

export const linkLabels = {
  growers: 'Growers',
  evalEst: 'Evaluations & Estimates',
  packPlan: 'Pack Plan',
  pickSchedule: 'Pick Schedule',
  payroll: 'Harvest Payroll',
  settings: 'Settings',
  users: 'Users',
  sync: 'Spokane Sync',
  setPools: 'Pools',
  setRates: 'Rates',
  setContractors: 'Contractors',
  setPayroll: 'Payroll',
};

export const links = [
  {
    icon: AgricultureIcon,
    label: linkLabels.growers,
    path: Constants.routes.GROWERS_LIST,
  },
  {
    icon: NaturePeopleIcon,
    label: linkLabels.evalEst,
    path: Constants.routes.EVAL_EST_MAIN,
  },
  {
    icon: AppRegistrationIcon,
    label: linkLabels.packPlan,
    path: Constants.routes.PACK_PLAN,
  },
  {
    icon: DateRangeIcon,
    label: linkLabels.pickSchedule,
    path: Constants.routes.PICK_SCHEDULE,
  },
  {
    icon: RequestQuoteIcon,
    label: linkLabels.payroll,
    path: Constants.routes.HARVEST_PAYROLL,
  },
  {
    icon: Settings,
    label: linkLabels.settings,
    path: Constants.routes.SETTINGS,
    subLinks: [
      {
        icon: SyncIcon,
        label: linkLabels.sync,
        path: `${Constants.routes.SETTINGS}${Constants.routes.SYNC}`,
      },
      {
        icon: TuneIcon,
        label: linkLabels.setPools,
        path: `${Constants.routes.SETTINGS}${Constants.routes.POOL_SETTINGS}`,
      },
      {
        icon: TuneIcon,
        label: linkLabels.setRates,
        path: `${Constants.routes.SETTINGS}${Constants.routes.RATE_SETTINGS}`,
      },
      {
        icon: TuneIcon,
        label: linkLabels.setContractors,
        path: `${Constants.routes.SETTINGS}${Constants.routes.CONTRACTOR_SETTINGS}`,
      },
      {
        icon: TuneIcon,
        label: linkLabels.setPayroll,
        path: `${Constants.routes.SETTINGS}${Constants.routes.PAYROLL_SETTINGS}`,
      },
      {
        icon: GroupIcon,
        label: linkLabels.users,
        path: `${Constants.routes.SETTINGS}${Constants.routes.USERS_LIST}`,
      },
    ],
  },
];
