import { useAppDispatch, useAppSelector } from 'app/redux';
import { handleError } from 'common/api/handleError';
import { useGetSeasonsQuery } from 'common/api/harvestDataApi';
import Header from 'common/components/Header';
import UserCard from 'common/components/UserCard';
import useWindowSize from 'common/hooks/useWindowSize';
import { Season } from 'common/models/season';
import { mobile } from 'common/styles/breakpoints';
import { useAuth } from 'features/auth/hooks';
import MobileNav from 'features/navbar/components/MobileNav';
import { SeasonSelector } from 'features/navbar/components/SeasonSelector/SeasonSelector';
import {
  seasonSlice,
  seasonSliceName,
  SeasonState,
} from 'features/navbar/components/SeasonSelector/seasonSlice';
import { NavMenu } from 'features/navbar/NavMenu';
import { useEffect, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Constants } from 'utils/constants';
import { determinePathOnLogin } from 'utils/helperFunction';
import { WithLoadingOverlay } from '../LoadingSpinner';
import {
  ContentContainer,
  SeasonContainer,
  SeasonError,
  SectionContainer,
  StyledNav,
  TopBar,
  Wrapper,
} from './styles';

/**
 * The main component to display the user card, the nav menu
 * and the header when an auth token is in state.
 */
const TokenLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { width } = useWindowSize();
  const isMobile = width < parseInt(mobile, 10);
  const { user } = useAuth();
  const { selectedSeason } = useAppSelector<SeasonState>(
    state => state[seasonSliceName],
  );
  const showSeasonSelector =
    pathname.includes(Constants.routes.GROWERS_LIST) ||
    pathname.includes(Constants.routes.EVAL_EST_MAIN) ||
    pathname.includes(Constants.routes.NEW_PICK_LIST);

  const { data, isLoading, isFetching, error } = useGetSeasonsQuery();

  const defaultSeason = useMemo(
    () => data?.find(ssn => ssn.isDefault) || null,
    [data],
  );

  const handleDisplay = () =>
    data && data.length > 0 ? (
      <WithLoadingOverlay isLoading={isLoading || isFetching}>
        {/* Only show components once default season is selected to avoid
            season-depedendent api route failure. */}
        {!!selectedSeason && children}
      </WithLoadingOverlay>
    ) : (
      <>
        {/* Only show error when accessing a season-dependent page. */}
        {showSeasonSelector ? (
          <SeasonError>
            No seasons are available. Please refresh the page to try again.
          </SeasonError>
        ) : (
          children
        )}
      </>
    );

  useEffect(() => {
    if (data && data.length > 0) {
      dispatch(seasonSlice.actions.setAvailableSeasons(data));
      if (!selectedSeason) {
        dispatch(seasonSlice.actions.setSeason(defaultSeason as Season));
      }
    }
  }, [data, defaultSeason, dispatch, selectedSeason]);

  useEffect(() => {
    if (error) {
      handleError(error, 'There was an issue retrieving seasons.');
    }
  }, [error]);

  return (
    <Wrapper>
      {isMobile ? (
        <MobileNav
          isLoading={isLoading || isFetching}
          showSeasonSelector={showSeasonSelector}
        >
          <ContentContainer>{handleDisplay()}</ContentContainer>
        </MobileNav>
      ) : (
        <>
          <StyledNav>
            <UserCard />
            <NavMenu />
          </StyledNav>
          <ContentContainer>
            <TopBar>
              {showSeasonSelector ? (
                <SeasonContainer>
                  <h4>Season</h4>
                  <SeasonSelector isLoading={isLoading || isFetching} />
                </SeasonContainer>
              ) : (
                <div />
              )}
              <Link to={determinePathOnLogin(user?.role.roleName)}>
                <Header variant='h4'>{Constants.legalCompanyName}</Header>
                <p>{Constants.appName}</p>
              </Link>
            </TopBar>
            <SectionContainer>{handleDisplay()}</SectionContainer>
          </ContentContainer>
        </>
      )}
    </Wrapper>
  );
};

export default TokenLayout;
