import { NotFoundView } from 'common/components/NotFound';
import { RoleType } from 'common/models';
import { PrivateRoute } from 'features/auth/components/privateRoute.tsx';
import { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Constants } from 'utils/constants';
import { CreateUserView } from './pages/CreateUserView';
import { UserDetailView } from './pages/UserDetailView';
import { UserListView } from './pages/UserListView';

export const UserRoutes: FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute
        exact
        path={path}
        component={UserListView}
        requiredRoles={[RoleType.Admin]}
      />
      <PrivateRoute
        exact
        path={`${path}${Constants.routes.DETAILS}/:id`}
        component={UserDetailView}
        requiredRoles={[RoleType.Admin]}
      />
      <PrivateRoute
        exact
        path={`${path}${Constants.routes.ADD}`}
        component={CreateUserView}
        requiredRoles={[RoleType.Admin]}
      />
      <Route component={NotFoundView} />
    </Switch>
  );
};
