import RefreshIcon from '@mui/icons-material/Refresh';
import { ErrorBoundary } from '@sentry/react';
import { useGetAppHealthQuery } from 'common/api/healthCheckApi';
import { GoogleMapsModal } from 'common/components/GoogleMapsModal/GoogleMapsModal';
import TokenLayout from 'common/components/HolyGrailLayout/TokenLayout';
import { NotFoundView } from 'common/components/NotFound';
import { NotificationContainer } from 'common/components/Notification';
import Splash from 'common/components/Splash';
import { RoleType } from 'common/models';
import { Routes as AuthRoutes } from 'features/auth/Routes';
import { PrivateRoute } from 'features/auth/components/privateRoute.tsx';
import { ConfirmationModal } from 'features/confirmation-modal';
import { EvalEstimatesRoutes } from 'features/evaluation-views/Routes';
import { GrowerRoutes } from 'features/grower-views/Routes';
import { HarvestPayrollRoutes } from 'features/harvest-payroll/Routes';
import { PackPlanRoutes } from 'features/pack-plan-views/Routes';
import { PickScheduleRoutes } from 'features/pick-schedule-views/Routes';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Constants } from 'utils/constants';
import AppTheme from 'utils/styleValues';
import { GlobalStyle } from '../GlobalStyle';
import { useAppSelector } from './redux';
import {
  versionSlice,
  versionSliceName,
  VersionSliceState,
} from './redux/versionSlice';
import { ReloadBtn } from './styles';
import { SettingsRoutes } from 'features/settings-view/Routes';

export const App: FC = () => {
  const dispatch = useDispatch();
  const { data } = useGetAppHealthQuery();
  const { showRefreshBtn } = useAppSelector<VersionSliceState>(
    state => state[versionSliceName],
  );

  // Set the initial version in the store on app load.
  useEffect(() => {
    if (data) {
      dispatch(versionSlice.actions.setVersion(data.version));
    }
  }, [data, dispatch]);

  return (
    <ErrorBoundary>
      <ThemeProvider theme={AppTheme}>
        <ConfirmationModal />
        <GoogleMapsModal />
        <NotificationContainer />
        {showRefreshBtn && (
          <ReloadBtn type='button' onClick={() => window.location.reload()}>
            New version available <RefreshIcon />
          </ReloadBtn>
        )}
        <Switch>
          <Route exact path='/' component={Splash} />
          <Route path='/auth' component={AuthRoutes} />
          <TokenLayout>
            <Switch>
              <PrivateRoute
                path={`${Constants.routes.GROWERS_LIST}`}
                component={GrowerRoutes}
                requiredRoles={[
                  RoleType.Admin,
                  RoleType.Analyst,
                  RoleType.FieldRep,
                ]}
              />
              <PrivateRoute
                path={`${Constants.routes.EVAL_EST_MAIN}`}
                component={EvalEstimatesRoutes}
                requiredRoles={[
                  RoleType.Admin,
                  RoleType.Analyst,
                  RoleType.FieldRep,
                ]}
              />
              <PrivateRoute
                path={`${Constants.routes.PACK_PLAN}`}
                component={PackPlanRoutes}
                requiredRoles={[
                  RoleType.Admin,
                  RoleType.Analyst,
                  RoleType.FieldRep,
                  RoleType.HarvestManager,
                ]}
              />
              <PrivateRoute
                path={Constants.routes.PICK_SCHEDULE}
                component={PickScheduleRoutes}
                requiredRoles={[
                  RoleType.Admin,
                  RoleType.Analyst,
                  RoleType.FieldRep,
                  RoleType.HarvestManager,
                  RoleType.HarvestCo,
                ]}
              />
              <PrivateRoute
                path={Constants.routes.HARVEST_PAYROLL}
                component={HarvestPayrollRoutes}
                requiredRoles={[RoleType.Admin]}
              />
              <PrivateRoute
                path={Constants.routes.SETTINGS}
                component={SettingsRoutes}
                requiredRoles={[RoleType.Admin]}
              />
              <Route component={NotFoundView} />
            </Switch>
          </TokenLayout>
          <Route component={NotFoundView} />
        </Switch>
      </ThemeProvider>
      <GlobalStyle />
    </ErrorBoundary>
  );
};
