import { yupResolver } from '@hookform/resolvers/yup';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { useAppSelector } from 'app/redux';
import { handleError } from 'common/api/handleError';
import {
  TransferRateDto,
  useGetTransferRatesQuery,
} from 'common/api/harvestDataApi';
import { useTimeframeSwitcherState } from 'common/components/TimeframeSwitcher/useTimeframeSwitcherState';
import { WizardDateTimePicker } from 'common/components/WizardControls/WizardDateTimePicker';
import {
  WizardDropdown,
  WizardDropdownOption,
} from 'common/components/WizardControls/WizardDropdown';
import { WizardNumericInput } from 'common/components/WizardControls/WizardNumericInput';
import { TransferLineType } from 'common/models/harvestData/payrollData';
import { orange } from 'common/styles/colors';
import { useTransferTable } from 'features/harvest-payroll/hooks/useTransferTable';
import {
  PayrollPageState,
  payrollSlice,
  payrollSliceName,
} from 'features/harvest-payroll/PayrollSlice';
import { commissionRate } from 'features/harvest-payroll/utils/tableRowDefaults';
import { FC, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { getWeek } from 'utils/dateTimeHelpers';
import { addTransferLineSchema } from 'utils/schemas/payrollSchema';
import { EmptyCell } from '../styles';
import {
  AddNewLineBtn,
  CancelBtn,
  LineItemTextarea,
  SubmitNewLineBtn,
  TransferDate,
  TransferDescriptionInput,
  TransferEntryContainer,
  TransferInfoContainer,
  TransferLineContainer,
  TransferQuantityInput,
  TransferRateInput,
  TransfersBtnsContainer,
} from './styles';

export type TransferRowValues = {
  date: string | null;
  details: string | null;
  glNumber: string | null;
  loads: string | null;
  rate: WizardDropdownOption<TransferRateDto> | null;
  commissionType: WizardDropdownOption<string> | null;
  commissionRate: string | null;
};

export type ValidatedTransferRow = {
  [K in keyof TransferRowValues]: NonNullable<TransferRowValues[K]>;
};

const commissionOption = {
  label: TransferLineType[TransferLineType.Commission],
  value: TransferLineType[TransferLineType.Commission],
};

/** Component to add 'Transfers' table line items. */
export const AddTransferLineForm: FC = () => {
  const prompt = 'Add Transfer';
  const dispatch = useDispatch();
  const { state: week } = useTimeframeSwitcherState('harvest-payroll', 'week');
  const { data: tansferRates, isLoading, error } = useGetTransferRatesQuery();

  // State value to show or hide the form.
  const [toggleOptions, setToggleOptions] = useState(false);

  const { addTransferRow } = useTransferTable();
  const { isDirty, newRowCount } = useAppSelector<PayrollPageState>(
    state => state[payrollSliceName],
  );

  const methods = useForm<TransferRowValues>({
    defaultValues: {
      date: null,
      details: null,
      glNumber: null,
      loads: null,
      rate: null,
      commissionType: commissionOption,
      commissionRate: commissionRate.toString(),
    },
    resolver: yupResolver(addTransferLineSchema),
    mode: 'all',
  });
  const {
    control,
    formState: { isSubmitSuccessful, errors },
    handleSubmit,
    register,
    reset,
  } = methods;

  const transferRateOptions: WizardDropdownOption<TransferRateDto>[] = useMemo(
    () =>
      (tansferRates || []).map(rate => ({ label: rate.route, value: rate })),
    [tansferRates],
  );

  const lastDayOfWeek = (): Date =>
    getWeek({
      date: week.startDate,
      offset: 1,
      startDay: 0,
    }).startDate;

  /** Form submission handler to add 'Transfer' table rows. */
  const handleTransferRow = (formData: TransferRowValues) => {
    addTransferRow(formData as ValidatedTransferRow, newRowCount);

    if (!isDirty) {
      dispatch(payrollSlice.actions.setIsDirty(true));
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  useEffect(() => {
    if (error) handleError(error, 'Unable to load transfer rates.');
  }, [error]);

  return toggleOptions ? (
    <FormProvider {...methods}>
      <TransferLineContainer onSubmit={handleSubmit(handleTransferRow)}>
        <TransferDate>
          <WizardDateTimePicker
            name='date'
            formControl={control}
            placeholder='MM/DD'
            sidePadding={1}
            showMonthDateFormat
            minDate={week.startDate}
            maxDate={lastDayOfWeek()}
          />
        </TransferDate>
        <TransferInfoContainer>
          <LineItemTextarea
            {...register('details')}
            placeholder='Enter details...'
            $isError={!!errors.details}
          />
          {/* The error Feedback control does not work without this hidden Input */}
          <Form.Check.Input hidden isInvalid={!!errors.details} />
          {!!errors.details && (
            <Form.Control.Feedback type='invalid'>
              <>{errors.details?.message}</>
            </Form.Control.Feedback>
          )}
        </TransferInfoContainer>
        <TransferEntryContainer>
          <LineItemTextarea
            {...register('glNumber')}
            placeholder='...'
            rows={1}
            $isError={!!errors.glNumber}
          />
          {/* The error Feedback control does not work without this hidden Input */}
          <Form.Check.Input hidden isInvalid={!!errors.glNumber} />
          {!!errors.glNumber && (
            <Form.Control.Feedback type='invalid'>
              <>{errors.glNumber?.message}</>
            </Form.Control.Feedback>
          )}
        </TransferEntryContainer>
        <TransferQuantityInput>
          <WizardNumericInput name='loads' />
        </TransferQuantityInput>
        <TransferDescriptionInput>
          <WizardDropdown
            name='rate'
            formControl={control}
            options={transferRateOptions}
            placeholder='Select a route...'
            isLoading={isLoading}
          />
          <WizardDropdown
            name='commissionType'
            formControl={control}
            options={[commissionOption]}
          />
        </TransferDescriptionInput>
        <TransferRateInput>
          <WizardNumericInput name='commissionRate' />
        </TransferRateInput>
        <EmptyCell />
        <EmptyCell />
        <TransfersBtnsContainer>
          <CancelBtn
            type='button'
            onClick={() => {
              setToggleOptions(false);
              reset();
            }}
          >
            Cancel
          </CancelBtn>
          <SubmitNewLineBtn type='submit'>
            <AddCircleOutlineOutlinedIcon htmlColor={orange} />
            {prompt}
          </SubmitNewLineBtn>
        </TransfersBtnsContainer>
      </TransferLineContainer>
    </FormProvider>
  ) : (
    <>
      <AddNewLineBtn type='button' onClick={() => setToggleOptions(true)}>
        <AddCircleOutlineOutlinedIcon htmlColor={orange} />
        {prompt}
      </AddNewLineBtn>
      <EmptyCell />
    </>
  );
};
