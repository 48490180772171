import { userCardHeight } from 'common/components/UserCard';
import {
  darkNavy,
  grey,
  lighterNavy,
  lightGreyBackground,
  orange,
  white,
} from 'common/styles/colors';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const topBarHeight = '60px';

export const MenuWrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    min-height: calc(100vh - ${topBarHeight} - ${userCardHeight});
  }
`;

export const LinkContainer = styled.div<{
  $isActive: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding 8px 16px;
  border-bottom: 1px solid ${grey};
  background: ${props => (props.$isActive ? darkNavy : 'none')};

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    border-radius: 4px;
    background: ${props => (props.$isActive ? orange : 'none')};

    &:hover {
      background: ${props => (props.$isActive ? orange : darkNavy)};
    }
  }

  svg {
    fill: ${white};
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;

export const MainLink = styled(NavLink)`
  display: flex;
  align-items: center;
  white-space: nowrap;

  span {
    margin-left: 5px;
  }
`;

export const SubLinksContainer = styled.div`
  display: flex;
  flex-direction: column;

  a {
    text-decoration: none;
  }
`;

export const SubLink = styled(MainLink)<{
  $isActive: boolean;
}>`
  height: 35px;
  padding-left: 40px;
  background-color: ${white};
  color: ${darkNavy};
  border-bottom: 1px solid ${grey};
  border-right: 1px solid ${grey};
  border-left: 1px solid ${grey};
  background-color: ${props =>
    props.$isActive ? lightGreyBackground : 'none'};
`;

export const LogoutBtn = styled.button`
  display: flex;
  justify-content: center;
  margin: auto 0 25px;
  background: none;
  border: none;
  color: inherit;

  p {
    margin-right: 5px;
  }
`;

// ----------- MOBILE STYLES -----------

export const FixedTopBar = styled.div`
  color: ${white};
  background-color: ${darkNavy};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${topBarHeight};
  position: sticky;
  top: 0;
  z-index: 100; // keeps the mobile top bar above child content when scrolling
`;

export const MobileLinks = styled.nav`
  background-color: ${lighterNavy};
  color: ${white};
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100%;
  min-height: calc(100vh - ${topBarHeight});
`;

export const LogoBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const MenuBar = styled.div<{
  $showSeasonSelector: boolean;
}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  // Center logo and title when season selector is hidden.
  padding: ${props => (props.$showSeasonSelector ? '0 10px' : '0 52px 0 10px')};
`;

export const menuIconStyle = {
  cursor: 'pointer',
  height: '100%',
  width: '42px',
};

export const logoIconStyle = {
  color: orange,
  fontSize: '35px',
};

export const ContentContainer = styled.div`
  flex-grow: 1;
`;
