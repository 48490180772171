import { yupResolver } from '@hookform/resolvers/yup';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { handleError } from 'common/api/handleError';
import { useGetHarvestCrewsQuery } from 'common/api/harvestDataApi';
import { useGetUsersByRolesQuery } from 'common/api/userApi';
import { validateCoordinateInfo } from 'common/components/LocationButton/LocationButton';
import {
  WizardDropdown,
  WizardDropdownOption,
} from 'common/components/WizardControls/WizardDropdown';
import {
  RoleHierarchy,
  ScheduledPick,
  User,
  pickTypeLabels,
} from 'common/models';
import { Contractor } from 'common/models/harvestData/contractor';
import {
  blueShade,
  darkNavy,
  lightGreyText,
  orange,
  white,
} from 'common/styles/colors';
import usePickScheduleActions, {
  AssignTeamPayload,
} from 'features/pick-schedule-views/hooks/usePickScheduleActions';
import { formatPickDetailDate } from 'features/pick-schedule-views/utils/pickDetailUtils';
import { FC, createContext, useEffect, useMemo, useState } from 'react';
import {
  FieldError,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { Constants } from 'utils/constants';
import { createContractorOptions } from 'utils/formValues/dropdownOptions';
import { crewAssignmentSchema } from 'utils/schemas/assignmentSchemas';
import {
  DataEntryRow,
  OrangeButton,
  OrangePrompt,
  Warning,
} from '../SchedulePickWizard/styles';
import { ItemContainer } from '../WizardComponents/ItemContainer';
import { PickDataWizardContainer } from '../WizardComponents/PickDataWizardContainer';
import { PickSummary } from '../WizardComponents/PickSummary';
import { WizardHeader } from '../WizardComponents/WizardHeader';
import {
  ButtonSection,
  CancelBtn,
  InfoLabel,
  Textarea,
  WizardDivider,
  WizardSection,
  selectBaseStyling,
} from '../WizardComponents/wizardStyles';
import { AssignCrewForm, ContractorAndBins } from './AssignCrewForm';
import { AssignHaulersForm, SelectedHauler } from './AssignHaulersForm';
import {
  AssignHaulers,
  AssignSection,
  TextareaPrompt,
  bigInputSize,
} from './styles';

type AssignmentValues = {
  coordinator: WizardDropdownOption<User> | null;
  contractorsAndBins: ContractorAndBins[];
  haulers: SelectedHauler[];
  notes: string | null;
};

export const CrewAssignmentContext = createContext<{
  contractorOptions: WizardDropdownOption<Contractor>[];
  isLoading: boolean;
}>(null!);

export const CrewAssignmentWizard: FC<{ scheduledPick: ScheduledPick }> = ({
  scheduledPick,
}) => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { assignHarvestTeam } = usePickScheduleActions();
  const { data, isLoading, error: fetchCrewsError } = useGetHarvestCrewsQuery();
  const {
    data: coordinators,
    isLoading: isLoadingCoordinators,
    error: fetchCoordError,
  } = useGetUsersByRolesQuery({
    roleIds: [
      RoleHierarchy['Harvest Coordinator'],
      RoleHierarchy['Harvest Manager'],
    ],
  });

  const assignedCoordinator: WizardDropdownOption<User> | null = useMemo(() => {
    return scheduledPick.coordinator
      ? {
          label: `${scheduledPick.coordinator?.firstName} ${scheduledPick.coordinator?.lastName}`,
          value: scheduledPick.coordinator,
        }
      : null;
  }, [scheduledPick]);

  const contractorOptions: WizardDropdownOption<Contractor>[] = useMemo(
    () => createContractorOptions(data?.fieldLaborContractors),
    [data],
  );

  const coordinatorsOptions: WizardDropdownOption<User>[] = useMemo(
    () =>
      coordinators?.map((coordinator: User) => ({
        label: `${coordinator.firstName} ${coordinator.lastName}`,
        value: coordinator,
      })) || [],
    [coordinators],
  );
  const assignedContractorsAndBins: Omit<ContractorAndBins, 'id'>[] =
    useMemo(() => {
      return (
        scheduledPick.pickAssignment?.contractorPickAssignment.map(
          contractorAndBins => ({
            contractor: {
              label: contractorAndBins.contractor?.code,
              value: contractorAndBins.contractor,
            },
            bins: contractorAndBins.bins.toString(),
          }),
        ) || []
      );
    }, [scheduledPick]);
  const assignedHaulers: Omit<SelectedHauler, 'id'>[] = useMemo(() => {
    return (
      scheduledPick.pickAssignment?.haulers?.map(hauler => ({
        hauler: {
          label: hauler.code,
          value: hauler,
        },
      })) || []
    );
  }, [scheduledPick]);

  const methods = useForm<AssignmentValues>({
    defaultValues: {
      coordinator: assignedCoordinator,
      contractorsAndBins: assignedContractorsAndBins,
      haulers: assignedHaulers,
      notes: scheduledPick.pickAssignment?.notes || null,
    },
    resolver: yupResolver(crewAssignmentSchema),
    mode: 'all',
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    getValues,
    clearErrors,
  } = methods;
  const {
    fields: contractorBinsFields,
    append: appendContractorBins,
    remove: removeContractorBins,
  } = useFieldArray({
    control,
    name: 'contractorsAndBins',
  });
  const {
    fields: haulerFields,
    append: appendHauler,
    remove: removeHauler,
  } = useFieldArray({
    control,
    name: 'haulers',
  });
  const [isAssigning, setIsAssigning] = useState(false);
  const mapParams = validateCoordinateInfo(
    scheduledPick?.block?.latitude,
    scheduledPick?.block?.longitude,
    scheduledPick?.block?.blockId,
  );

  const assignTeamForPick = async ({
    coordinator,
    contractorsAndBins,
    haulers,
    notes,
  }: AssignmentValues) => {
    setIsAssigning(true);

    const isSuccessful = await assignHarvestTeam({
      scheduledPickId: parseInt(id, 10),
      ...(scheduledPick.pickAssignment && {
        pickAssignmentId: scheduledPick.pickAssignment.id,
      }),
      coordinatorId: coordinator?.value.id,
      contractorPickAssignments: contractorsAndBins.map(item => ({
        contractorId: item.contractor?.value.id,
        bins: item.bins && parseInt(item.bins, 10),
      })),
      ...(haulers && { haulers: haulers.map(item => item.hauler?.value) }),
      notes,
    } as AssignTeamPayload); // Force type here because validation
    // must have passed by now.
    setIsAssigning(false);
    if (isSuccessful) history.push(`${Constants.routes.PICK_SCHEDULE}/${id}`);
  };

  useEffect(() => {
    if (fetchCrewsError) handleError(fetchCrewsError, 'Unable to load crews.');
    if (fetchCoordError)
      handleError(
        fetchCoordError,
        'Unable to load harvest coordinators and managers.',
      );
  }, [history, id, fetchCrewsError, fetchCoordError]);

  return (
    <FormProvider<AssignmentValues> {...methods}>
      <CrewAssignmentContext.Provider
        value={{
          contractorOptions,
          isLoading,
        }}
      >
        <PickDataWizardContainer
          backgroundColor={darkNavy}
          onSubmit={handleSubmit(assignTeamForPick)}
        >
          <WizardHeader
            growerId={scheduledPick.block?.grower?.growerId}
            blockId={scheduledPick.block?.blockId}
            evaluator={`${scheduledPick.block?.primaryEvaluator?.firstName} ${scheduledPick.block?.primaryEvaluator?.lastName}`}
            varietyCode={scheduledPick.block?.variety?.varietyCode}
            binsRemaining={scheduledPick.pickRecord?.binsLeft}
            size={scheduledPick.size?.value}
            mapParams={mapParams}
            locationStyles={{
              backgroundColor: orange,
              iconColor: lightGreyText,
            }}
            textColor={blueShade}
            showSize
          />
          <PickSummary
            packHouseCode={scheduledPick.packHouse?.code}
            size={scheduledPick.size?.value}
            market={scheduledPick.market || undefined}
            pickDay={formatPickDetailDate(scheduledPick.schedule.pickDay)}
            binsToPick={scheduledPick.bins.toString()}
            pool={scheduledPick?.pool?.poolId}
            cleanPick={scheduledPick.cleanPick ? 'Yes' : 'No'}
            pickType={
              typeof scheduledPick.pickType === 'number'
                ? pickTypeLabels[scheduledPick.pickType]
                : undefined
            }
            notes={scheduledPick.notes}
            textColor={blueShade}
          />
          {scheduledPick.pickRecord && (
            <Warning style={{ marginTop: '10px' }}>
              <ErrorOutlineIcon />
              <span>
                Warning: changing these assignments does not change the
                contractors already entered for the pick. This also unapproves
                the day&apos;s schedule so it may need to be resent.
              </span>
            </Warning>
          )}
          <AssignSection>
            <WizardSection>
              <OrangePrompt>Assign Staff/Crew</OrangePrompt>
              <DataEntryRow>
                <InfoLabel>HC</InfoLabel>
                <WizardDropdown<User>
                  name='coordinator'
                  options={coordinatorsOptions}
                  formControl={control}
                  styles={selectBaseStyling}
                  isLoading={isLoadingCoordinators}
                  maxWidth={bigInputSize}
                />
              </DataEntryRow>
              <ItemContainer<ContractorAndBins>
                itemName='Crew'
                items={contractorBinsFields}
                addItem={() => {
                  if (!getValues('contractorsAndBins').length)
                    clearErrors('contractorsAndBins');
                  return appendContractorBins({
                    id: '',
                    contractor: null,
                    bins: null,
                  });
                }}
                removeItem={(index: number) => removeContractorBins(index)}
                error={
                  (errors?.contractorsAndBins as unknown as FieldError)?.message
                }
                ItemComponent={AssignCrewForm}
                iconColor={orange}
                textColor={white}
              />
            </WizardSection>
            <AssignHaulers>
              <OrangePrompt>Assign Haul-Only Crew</OrangePrompt>
              <ItemContainer<SelectedHauler>
                itemName='Hauler'
                items={haulerFields}
                addItem={() =>
                  appendHauler({
                    id: '',
                    hauler: null,
                  })
                }
                removeItem={(index: number) => removeHauler(index)}
                error={(errors?.haulers as unknown as FieldError)?.message}
                ItemComponent={AssignHaulersForm}
                iconColor={orange}
                textColor={white}
              />
            </AssignHaulers>
          </AssignSection>
          <WizardDivider />
          <WizardSection>
            <TextareaPrompt>Add Harvest Notes:</TextareaPrompt>
            <Textarea {...register('notes')} rows={2} />
          </WizardSection>
          <ButtonSection>
            <CancelBtn
              type='button'
              onClick={() =>
                history.push(`${Constants.routes.PICK_SCHEDULE}/${id}`)
              }
              disabled={isAssigning}
            >
              Cancel
            </CancelBtn>
            <OrangeButton type='submit' disabled={isAssigning}>
              CONFIRM
            </OrangeButton>
          </ButtonSection>
        </PickDataWizardContainer>
      </CrewAssignmentContext.Provider>
    </FormProvider>
  );
};
