import { RootState, useAppDispatch, useAppSelector } from 'app/redux';
import { useMemo } from 'react';
import { getMonth, getWeek, Month, Week } from 'utils/dateTimeHelpers';
import {
  SwitcherAction,
  switcherSliceName,
  timeframeSwitcherSlice,
} from './timeframeSwitcherSlice';

/** The unique identifiers under which a switcher component's state is saved. */
export type SwitcherIds =
  | 'pack-plan'
  | 'pick-schedule'
  | 'harvest-payroll'
  | 'fuel-surcharges';

/** The relative time to switch between. */
export type Timeframe = 'week' | 'month';

export type WeekSwitcherState = Week & {
  offset: number;
};

export type MonthSwitcherState = Month & {
  offset: number;
};

type SwitcherState<T extends WeekSwitcherState | MonthSwitcherState> = {
  /** Object containing the identified timeframe switcher's state. */
  state: T;
  /**
   * Sets the timeframe offset to a new value.
   * @param adjustment number to offset from the current timeframe.
   */
  useSetOffset: (adjustment: number) => void;
  /** Decrements the current timeframe offset by 1. */
  useDecrement: () => void;
  /** Increments the current timeframe offset by 1. */
  useIncrement: () => void;
};

type TimeframeHookResult<F> = F extends 'week'
  ? SwitcherState<WeekSwitcherState>
  : F extends 'month'
  ? SwitcherState<MonthSwitcherState>
  : never;

/**
 * Hook to get and set state for a TimeframeSwitcher component
 * with the corresponding identifier.
 */
export const useTimeframeSwitcherState = <F extends Timeframe>(
  identifier: SwitcherIds,
  timeframeType: F,
): TimeframeHookResult<F> => {
  const dispatch = useAppDispatch();

  const storedOffset =
    useAppSelector(
      (rootState: RootState) => rootState[switcherSliceName][identifier],
    ) ?? 0;

  const timeframe = useMemo(
    () =>
      timeframeType === 'week'
        ? getWeek({ offset: storedOffset })
        : getMonth(storedOffset),
    [storedOffset, timeframeType],
  );

  const useSetOffset = useMemo(() => {
    return (change: number) => {
      const switcherAction: SwitcherAction = {
        key: identifier,
        newOffset: change,
      };
      dispatch(timeframeSwitcherSlice.actions.setOffset(switcherAction));
    };
  }, [dispatch, identifier]);

  function useDecrement() {
    useSetOffset(storedOffset - 1);
  }

  function useIncrement() {
    useSetOffset(storedOffset + 1);
  }

  return {
    state: { ...timeframe, offset: storedOffset },
    useSetOffset,
    useDecrement,
    useIncrement,
  } as TimeframeHookResult<F>;
};
