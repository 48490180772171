import { SizeEstimate } from 'common/models/growerBlock/sizeEstimate';
import { FC } from 'react';
import { border, cellStyle } from './styles';

export const SizeEstimateTable: FC<{
  width: string;
  estimates: SizeEstimate[];
}> = ({ estimates, width }) => (
  <table style={{ border, width, marginTop: '5px' }}>
    <thead>
      <tr>
        <th
          style={{
            ...cellStyle,
            borderRight: border,
            borderBottom: border,
          }}
        >
          Size
        </th>
        {estimates.map(estimate => (
          <th key={estimate.id} style={{ ...cellStyle }}>
            {estimate.size.value}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      <tr>
        <td style={{ ...cellStyle, borderRight: border }}>%</td>
        {estimates.map(estimate => (
          <td
            key={estimate.id}
            style={{ ...cellStyle, fontFamily: 'KanitLight' }}
          >
            {estimate.percentValue > 0 ? `${estimate.percentValue}` : '-'}
          </td>
        ))}
      </tr>
    </tbody>
  </table>
);
