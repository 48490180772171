import { handleError } from 'common/api/handleError';
import { CsvButton, CsvHeaders } from 'common/components/CsvButton/CsvButton';
import { CmsDate } from 'common/models/cmsDate';
import { useEffect, useState } from 'react';
import { UseQuery } from 'rtk-query-config';

const defaultName = 'export';

type AsyncCsvButtonProps<T> = {
  /** An rtk hook to fetch data. */
  fetcher: UseQuery<T>;
  /** Optional params to use with the rtk hook. */
  params?: Record<string, unknown>;
  /**
   * A utility function to format the fetcher's data into headers
   * and rows that the {@link CsvButton} component can read.
   * */
  formatter: (data: T) => {
    headers: CsvHeaders<string>[];
    rows: Record<string, string>[];
  };
  /** An optional label for the button. */
  label?: string;
  /**
   * An optional param object to create the filename.
   * The default `name` is {@link defaultName} and `appendDate` is set to
   * `true` by default.
   * */
  filenameOptions?: { name: string; appendDate: boolean };
  /**
   * An optional string to be used as the button's id. This can be useful if
   * needing to use multiple {@link AsyncCsvButton}s on one page.
   */
  buttonId?: string;
};
/**
 * A button component to asynchronously fetch data for subsequent formatting
 * and exporting to CSV.
 *
 * @remarks
 *
 * This component extends the `CsvButton` component to add the ability to
 * asynchronously fetch data, which is notoriously missing from the component
 * as it is.
 *
 * This is implemented as a two-stage process: first, the user's click
 * begins the asynchronous retrieval of data, and when that completes,
 * a second click is programmatically triggered to complete the process
 * of formatting and downloading the CSV file.
 */
export const AsyncCsvButton = <T,>({
  fetcher,
  params = undefined,
  formatter,
  label = 'Export',
  filenameOptions = { name: defaultName, appendDate: true },
  buttonId = 'async-export-btn',
}: AsyncCsvButtonProps<T>) => {
  const [fetchData, setFetchData] = useState(false);
  const [dataExported, setDataExported] = useState(false);
  const [headers, setHeaders] = useState<CsvHeaders<string>[]>([]);
  const [rows, setRows] = useState<Record<string, string>[]>([]);

  const { data, isLoading, error, isSuccess } = fetcher(params, {
    // Only fetch when the user first clicks on the button.
    skip: !fetchData,
  });
  const dataIsFormatted = !!(rows.length && headers.length);
  const handleClick = () => {
    if (dataIsFormatted) {
      // Second programmatic click.
      setDataExported(true);
      return true;
    }
    // First user click.
    setFetchData(true);
    return false;
  };
  // Format and set csv data.
  useEffect(() => {
    if (isSuccess && data) {
      const { rows, headers } = formatter(data);
      setFetchData(false);
      setHeaders(headers);
      setRows(rows);
    }
  }, [data, formatter, isSuccess]);
  // Programatically click on export button.
  useEffect(() => {
    if (dataIsFormatted) {
      document.getElementById(buttonId)?.click();
    }
  }, [buttonId, dataIsFormatted]);
  // Clean up after data is exported.
  useEffect(() => {
    if (dataExported) {
      setHeaders([]);
      setRows([]);
      setDataExported(false);
    }
  }, [dataExported]);
  useEffect(() => {
    if (error) {
      handleError(error, 'There was an issue exporting data.');
    }
  }, [error]);
  return (
    <CsvButton
      id={buttonId}
      label={label}
      headers={headers}
      data={rows}
      filename={`${filenameOptions.name}${
        filenameOptions.appendDate
          ? `_${CmsDate.fromDateOnly(new Date())?.toISO()}`
          : ''
      }`}
      isLoading={isLoading}
      onClick={handleClick}
    />
  );
};
