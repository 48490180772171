import { BlockDetails, WithAllSeasonData } from 'common/api/dto/get-block.dto';
import { RowBuilder } from 'common/components/DataTable';
import { DataTableText } from 'common/components/DataTable/DataTableStyles';
import VarietyCell from 'common/components/DataTable/VarietyCell';
import { useCheckboxColumn } from 'common/components/DataTable/hooks/useCheckboxColumn';
import { useChevronColumn } from 'common/components/DataTable/hooks/useChevronColumn';
import { MapParameters } from 'common/components/GoogleMapsModal/GmapsModalSlice';
import {
  LocationButton,
  multipleLocationTooltip,
  validateCoordinateInfo,
} from 'common/components/LocationButton/LocationButton';
import { Grower } from 'common/models';
import { CmsDate } from 'common/models/cmsDate';
import { BlockStatus, Variety } from 'common/models/growerBlock';
import { lightGreyText, orange } from 'common/styles/colors';
import { StatusIcon } from 'features/grower-views/components/StatusIcon';
import { useMemo, useState } from 'react';
import { Column } from 'react-table';
import { sortSizeEstimates } from 'utils/formValues/sizeEstimates';
import { CollapsibleRow } from '../components/CollapsibleRow/CollapsibleRow';

export type BlockEvalTableItem = {
  buildExpandedRow: RowBuilder;
  blockStatus?: BlockStatus;
  blockInfo: {
    id: number;
    blockId: string;
    grower: Partial<Grower>;
  };
  grower: Partial<Grower>;
  lotNumber: string | null;
  variety: Variety | null;
  latestEval?: string;
  latestHarvest?: string;
  estimatedBins?: number;
  binsToHarvest?: number;
  harvestPhase?: number;
  location: MapParameters | null;
};

export type UseBlockEvalTableData = (
  isLoading: boolean,
  agents?: BlockDetails<WithAllSeasonData>[],
) => {
  columns: Column<BlockEvalTableItem>[];
  data: BlockEvalTableItem[];
};

export const useBlockEvalTableData: UseBlockEvalTableData = (
  isLoading,
  growerBlocks = [],
) => {
  const [locations, setLocations] = useState<MapParameters[]>([]);
  const [locationsToShow, setLocationsToShow] = useState<MapParameters[]>([]);

  const toggleSizesColumn: Column<BlockEvalTableItem> =
    useChevronColumn('30px');

  const locationsColumn: Column<BlockEvalTableItem> = useCheckboxColumn(
    'location',
    '80px',
    locations,
    setLocationsToShow,
    () => (
      <LocationButton
        backgroundColor={orange}
        iconColor={lightGreyText}
        mapParams={locationsToShow}
        disabledTooltip={multipleLocationTooltip}
      />
    ),
    isLoading,
  );

  const columns: Column<BlockEvalTableItem>[] = useMemo(
    () => [
      toggleSizesColumn,
      {
        accessor: 'blockStatus',
        width: '3%',
        Cell: ({ value: blockStatus }) => (
          <StatusIcon status={blockStatus} type='block' />
        ),
      },
      {
        accessor: 'grower',
        Header: 'Grower ID',
        Cell: ({ value: growerData }) => (
          <DataTableText>{`${growerData?.growerId}`}</DataTableText>
        ),
      },
      {
        accessor: 'blockInfo',
        Header: 'Block ID',
        Cell: ({ value: blockInfo }) => (
          <DataTableText>{`${blockInfo.blockId}`}</DataTableText>
        ),
      },
      {
        accessor: 'lotNumber',
        Header: 'Lot',
        Cell: ({ value: lotNumber }) => (
          <DataTableText>{lotNumber || '-'}</DataTableText>
        ),
      },
      {
        accessor: 'variety',
        Header: 'Variety',
        Cell: ({ value }) => <VarietyCell variety={value} />,
      },
      {
        accessor: 'latestEval',
        Header: 'Latest Eval.',
        width: '10%',
        Cell: ({ value }) => <DataTableText>{value ?? '-'}</DataTableText>,
      },
      {
        accessor: 'latestHarvest',
        Header: 'Latest Harv.',
        width: '10%',
        Cell: ({ value }) => <DataTableText>{value ?? '-'}</DataTableText>,
      },
      {
        accessor: 'estimatedBins',
        Header: 'Latest Est.',
        Cell: ({ value }) => <DataTableText>{value || '0'}</DataTableText>,
      },
      {
        accessor: 'binsToHarvest',
        Header: 'Bins to Go',
        Cell: ({ value: binsToGo }) => (
          <DataTableText>{binsToGo || '0'}</DataTableText>
        ),
      },
      {
        accessor: 'harvestPhase',
        Header: '% Harvested',
        Cell: ({ value: harvestPhase }) => (
          <DataTableText>
            {harvestPhase ? `${harvestPhase}%` : '0'}
          </DataTableText>
        ),
      },
      locationsColumn,
    ],
    [locationsColumn, toggleSizesColumn],
  );

  const data: BlockEvalTableItem[] = useMemo(
    () =>
      growerBlocks.map((block, index) => {
        const {
          id,
          grower,
          blockId,
          variety,
          lotNumber,
          trees,
          acres,
          latitude,
          longitude,
          seasonData,
        } = block;

        const location = validateCoordinateInfo(latitude, longitude, blockId);

        if (location) {
          setLocations(prev => {
            // When the index is zero, we know data is being reloaded (e.g., filter
            // applied or page change), so we take the chance to clear previous
            // locations, otherwise we would preserve stale selections. This is a
            // workaround for the inability to clear selections via useEffect.
            if (index === 0) return [location];
            return [...prev, location];
          });
        }

        const sortedSizeEstimates = seasonData?.sizeEstimates
          ? sortSizeEstimates([...seasonData.sizeEstimates])
          : [];

        return {
          buildExpandedRow: () => (
            <CollapsibleRow
              sizeEstimates={sortedSizeEstimates}
              details={{
                blockId: id,
                gibbRate: seasonData?.evaluation?.gibbRate,
                market: seasonData?.evaluation?.market,
                trees,
                acres,
              }}
            />
          ),
          blockInfo: { id, blockId, grower },
          blockStatus: seasonData?.status,
          grower,
          lotNumber,
          variety,
          latestEval: CmsDate.parse(
            seasonData?.evaluation?.evaluationDate,
          )?.toString(),
          latestHarvest: CmsDate.parse(
            seasonData?.harvestProgress?.latestHarvestDate,
          )?.toString(),
          estimatedBins: seasonData?.harvestProgress?.latestEstimate,
          binsToHarvest: seasonData?.harvestProgress?.externalBinsLeft,
          harvestPhase: seasonData?.harvestProgress?.externalHarvestPhase,
          location,
        };
      }),
    [growerBlocks],
  );

  return { columns, data };
};
