import { mobile } from 'common/styles/breakpoints';
import { blueShadeHeader } from 'common/styles/colors';
import { boxShadow } from 'common/styles/page';
import styled from 'styled-components';

export const MainContainer = styled.div<{
  $maxWidth?: string;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  box-shadow: ${boxShadow};
  border-radius: 8px;
  width: 100%;
  max-width: ${({ $maxWidth }) => $maxWidth || ''};

  @media (max-width: ${mobile}) {
    padding: 10px 5px;
    box-shadow: none;
    border-radius: 0;
  }
`;

export const MainTitle = styled.h1`
  margin: 0;
  font-family: KanitLight;
  font-size: 32px;
  color: ${blueShadeHeader};
`;
