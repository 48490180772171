import { grey, tableBodyGrey } from 'common/styles/colors';
import styled from 'styled-components';

export const border = `1px solid ${grey}`;

export const cellStyle = {
  minWidth: '40px',
  textAlign: 'center',
  padding: '3px 5px',
  color: tableBodyGrey,
  fontFamily: 'KanitRegular',
} as React.CSSProperties;

export const CollapsedRowContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  max-height: 125px;
  max-width: ${(props: { maxWidth?: string }) =>
    props.maxWidth || 'fit-content'};

  p {
    margin: 0;
    font-family: KanitRegular;
  }
`;
