import { Logout } from '@mui/icons-material';
import { SubText } from 'common/styles/texts';
import { FC, MouseEventHandler } from 'react';
import { CustomNavLink } from './components/CustomNavLink';
import { LogoutBtn, MenuWrapper } from './components/styles';
import { useLogoutModal } from './hooks/useLogoutModal';
import { useNavLinks } from './hooks/useNavLinks';

export const NavMenu: FC<{
  closeMobileNav?: MouseEventHandler<HTMLElement>;
}> = ({ closeMobileNav }) => {
  const navLinks = useNavLinks();
  const { openLogoutModal } = useLogoutModal();

  return (
    <MenuWrapper>
      {navLinks.map(link => (
        <CustomNavLink
          key={link.label}
          link={link}
          closeMobileNav={closeMobileNav}
        />
      ))}
      <LogoutBtn type='button' onClick={openLogoutModal}>
        <SubText>Log Out</SubText>
        <Logout />
      </LogoutBtn>
    </MenuWrapper>
  );
};
