import {
  lightGreyBackground,
  lighterNavy,
  tableHeaderGrey,
} from 'common/styles/colors';
import styled, { css } from 'styled-components';

export const SwitcherTextStyle = css`
  font-family: KanitSemiBold;
  font-size: 20px;
  color: ${lighterNavy};
`;

export const SwitcherContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;

  span {
    display: flex;
    gap: 10px;
  }

  p {
    ${SwitcherTextStyle}
    white-space: nowrap;
    margin: 0;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: ${lightGreyBackground};
    border-radius: 50%;
    width: 28px;
    height: 28px;
    color: ${tableHeaderGrey};
  }
`;
