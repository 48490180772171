import { useAppDispatch, useAppSelector } from 'app/redux';
import { CsvButton } from 'common/components/CsvButton/CsvButton';
import { TimeframeSwitcher } from 'common/components/TimeframeSwitcher/TimeframeSwitcher';
import { useTimeframeSwitcherState } from 'common/components/TimeframeSwitcher/useTimeframeSwitcherState';
import {
  WizardDropdown,
  WizardDropdownOption,
} from 'common/components/WizardControls/WizardDropdown';
import { Contractor } from 'common/models';
import {
  InvoiceSummaryLine,
  PayrollData,
} from 'common/models/harvestData/payrollData';
import {
  allContractorsCode,
  allContractorsLabel,
  PayrollPageState,
  payrollSliceName,
} from 'features/harvest-payroll/PayrollSlice';
import { AccountsPayableHeaders } from 'features/harvest-payroll/utils/payrollDataTypes';
import {
  generateAllApCsv,
  generateFilename,
} from 'features/harvest-payroll/utils/payrollPageUtils';
import { FC, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { Constants } from 'utils/constants';
import { CsvButtonWrapper } from './CsvButtonWrapper';
import {
  DateRange,
  DropdownAndWeekSwitcher,
  HeaderForm,
  TitleAndCsvBtn,
} from './styles';

type SelectedContractor = {
  contractor: WizardDropdownOption<Contractor> | null;
};

export const PayrollHeader: FC<{
  summaryData: InvoiceSummaryLine[] | undefined;
  payrollData: PayrollData | undefined;
  contractorOptions: WizardDropdownOption<Contractor>[];
  isLoadingContractors: boolean;
  isLoadingPayrollData: boolean;
  isLoadingSummary: boolean;
}> = ({
  summaryData,
  payrollData,
  contractorOptions,
  isLoadingContractors,
  isLoadingPayrollData,
  isLoadingSummary,
}) => {
  const history = useHistory();
  const { contractorId } = useParams<{ contractorId: string }>();
  const dispatch = useAppDispatch();
  const { state: week } = useTimeframeSwitcherState('harvest-payroll', 'week');
  const { isEditable, selectedContractor } = useAppSelector<PayrollPageState>(
    state => state[payrollSliceName],
  );

  const { control, setValue, watch } = useForm<SelectedContractor>({
    defaultValues: {
      contractor: selectedContractor
        ? {
            label:
              selectedContractor.code === allContractorsCode
                ? allContractorsLabel
                : selectedContractor.code,
            value: selectedContractor,
          }
        : null,
    },
  });

  const dateRange = useMemo(() => {
    const firstDay = week.weekdays[0];
    const lastDay = week.weekdays[6];
    return (
      `${firstDay.weekday} ${firstDay.month}/${firstDay.day}` +
      ` - ${lastDay.weekday} ${lastDay.month}/${lastDay.day}`
    );
  }, [week]);

  // NOTE: the order of the following useEffects matters.

  // On first load or back button click, the parent component updates the
  // selected contractor. We manually update the form to match this change.
  useEffect(() => {
    const contractor = contractorOptions?.find(
      option => option.value.id === selectedContractor?.id,
    );
    setValue('contractor', contractor || contractorOptions[0]);
  }, [contractorOptions, selectedContractor, setValue]);

  // Form selection triggers url change.
  useEffect(() => {
    const subscription = watch(data => {
      if (
        data.contractor?.value &&
        parseInt(contractorId, 10) !== data.contractor.value.id &&
        contractorId !== data.contractor.value.code
      ) {
        history.push(
          `${Constants.routes.HARVEST_PAYROLL}/${
            data.contractor.value.id
              ? data.contractor.value.id
              : allContractorsCode
          }`,
        );
      }
    });

    return () => subscription.unsubscribe();
  }, [contractorId, dispatch, history, watch]);

  return (
    <>
      <TitleAndCsvBtn>
        <h1>Harvest Payroll</h1>
        {selectedContractor?.id &&
          !!payrollData &&
          !!payrollData?.picks.length &&
          !isLoadingPayrollData && (
            <CsvButtonWrapper
              week={week}
              payrollData={payrollData}
              selectedContractor={selectedContractor}
            />
          )}
        {!selectedContractor?.id &&
          selectedContractor?.code === allContractorsCode &&
          !!summaryData?.length &&
          !isLoadingSummary && (
            <CsvButton
              label='Export All Summary'
              headers={AccountsPayableHeaders}
              data={generateAllApCsv(summaryData, week)}
              filename={generateFilename(week, undefined, true)}
            />
          )}
      </TitleAndCsvBtn>
      <HeaderForm>
        <DropdownAndWeekSwitcher>
          <WizardDropdown<Contractor | null>
            name='contractor'
            formControl={control}
            options={contractorOptions}
            isLoading={isLoadingContractors}
            isDisabled={isLoadingPayrollData || isEditable}
          />
          <TimeframeSwitcher
            identifier='harvest-payroll'
            timeframe='week'
            disableButtons={isLoadingPayrollData || isEditable}
          />
        </DropdownAndWeekSwitcher>
        <DateRange>{dateRange}</DateRange>
      </HeaderForm>
    </>
  );
};
