import { FC, ReactElement, ReactNode } from 'react';
import { Column, useExpanded, usePagination, useTable } from 'react-table';
import {
  TableContainer,
  TableFooter,
  TitleSortFilterContainer,
  TitleToolbar,
} from './DataTableStyles';
import { Paginator } from './Paginator/Paginator';
import {
  SortFilterHandler,
  TableFilterProps,
  TableSortProps,
} from './SortFilterHandler/SortFilterHandler';
import { TableBody } from './TableBody/TableBody';

export type PaginationState = {
  page: number;
  pageSize: number;
  count: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
};

type PaginationActions = {
  getPage: (page: number) => void;
  getNextPage: () => void;
  getPreviousPage: () => void;
  changePageSize: (size: number) => void;
};

export type Option = {
  label: string;
  value: string;
};

export type RowBuilder = () => ReactNode;

export type DataTablePaginationProps = PaginationState & PaginationActions;

export type TableSortFilterParams = {
  category: {
    label: string;
    /** Sort and filter operator used server-side. */
    key: string;
  };
  /**
   * The options to render for the specified category.
   *
   *  @remarks
   * - Since sorts and filters can be fetched, options can be `undefined`.
   * - Passing a jagged array results in separators rendered in between each inner array.
   */
  options: Option[] | Option[][] | undefined;
  singleSelect?: boolean;
  /** Optional text to show below sort or filter title. */
  subHeader?: string;
};

export type DataTableProps<D extends Record<string, unknown>> = {
  title?: string;
  unitToPaginate?: string;
  isLoading: boolean;
  isFetching: boolean;
  columns: Column<D>[];
  data: D[];
  pagination?: DataTablePaginationProps;
  filterBy?: TableFilterProps;
  sortBy?: TableSortProps;
  expandableRows?: boolean;
  /**
   * @returns {string | undefined}
   * - If a string is returned, the row will be navigated to that URL.
   * - If undefined is returned, the row will not be clickable.
   *
   * @deprecated Use `onCellClick` instead.
   */
  onRowClick?: (item: D) => string | undefined;
  onCellClick?: {
    buildUrlPath: (item: D) => string;
    cellsToIgnore: (keyof D)[];
  };
  loadError?: boolean;
  /** Component to display on the right side of the table title. */
  toolBar?: ReactNode;
  /** Component to display on the right side of the table footer. */
  FooterComponent?: FC;
};

export const DataTable = <D extends Record<string, unknown>>({
  title,
  unitToPaginate,
  isLoading,
  isFetching,
  columns,
  data,
  pagination,
  filterBy,
  sortBy,
  expandableRows,
  onRowClick,
  onCellClick,
  toolBar,
  FooterComponent,
  loadError,
}: DataTableProps<D>): ReactElement => {
  // Evaluate these conditions once instead of re-computing in multiple places.
  const paginationEnabled = pagination !== undefined;

  const plugins = [
    // The order in which plugins are specified matters to react-table.
    // Order needs to be useExpanded -> usePagination.
    ...(expandableRows ? [useExpanded] : []),
    ...(paginationEnabled ? [usePagination] : []),
  ];

  const initialState = {
    ...(paginationEnabled
      ? {
          pageIndex: pagination.page,
          pageSize: pagination.pageSize,
        }
      : {}),
  };

  const extraTableOptions = {
    ...(paginationEnabled
      ? { manualPagination: true, pageCount: pagination.pageCount }
      : {}),
  };

  // In order to identify whether a column has footers, we default the footer
  // to an empty string and check against that.
  const defaults = { Footer: '' };

  const tableInstance = useTable(
    {
      columns,
      defaultColumn: defaults,
      data,
      initialState,
      ...extraTableOptions,
    },
    ...plugins,
  );

  const { getTableProps, headerGroups, footerGroups, rows, page } =
    tableInstance;

  // If the 'usePagination' plugin is enabled, the 'page' instance prop contains
  // the table rows for the current page. Otherwise, the 'rows' instance prop
  // contains the table rows.
  const tableRows = paginationEnabled ? page : rows;

  const isLoadOrFetch = isLoading || isFetching;
  const hasTableData = tableRows.length > 0;
  const hasFooterData = footerGroups.some(footerGroup => {
    return footerGroup.headers.some(column => column.Footer !== '');
  });

  return (
    <TableContainer>
      <TitleSortFilterContainer>
        <TitleToolbar>
          <h3>{title}</h3>
          {toolBar}
        </TitleToolbar>
        {(sortBy || filterBy) && (
          <SortFilterHandler sortProps={sortBy} filterProps={filterBy} />
        )}
      </TitleSortFilterContainer>

      <div className='scrollable-container'>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    style={{ width: `${column.width}` }}
                    {...column.getHeaderProps()}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <TableBody
            isLoadOrFetch={isLoadOrFetch}
            pagination={pagination}
            onRowClick={onRowClick}
            onCellClick={onCellClick}
            tableInstance={tableInstance}
            tableRows={tableRows}
            loadError={loadError}
          />

          {!isLoadOrFetch && hasTableData && hasFooterData && (
            <tfoot>
              {footerGroups.map(group => (
                <tr {...group.getFooterGroupProps()}>
                  {group.headers.map(column => (
                    <td {...column.getFooterProps()}>
                      {column.render('Footer')}
                    </td>
                  ))}
                </tr>
              ))}
            </tfoot>
          )}
        </table>
      </div>

      <TableFooter>
        {!isLoadOrFetch && paginationEnabled && hasTableData && (
          <Paginator
            {...pagination}
            unitToPaginate={unitToPaginate}
            isFetching={isFetching}
          />
        )}
        {FooterComponent && FooterComponent({})}
      </TableFooter>
    </TableContainer>
  );
};
