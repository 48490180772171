import { GibbRate } from 'common/models/evaluation';
import { FC } from 'react';
import { border, cellStyle } from './styles';

export type ExtraDetails = {
  blockId: number;
  gibbRate?: GibbRate;
  market?: string;
  trees: number | null;
  acres: number | null;
};

export const AdditionalDetailsTable: FC<{ width: string } & ExtraDetails> = ({
  width,
  blockId,
  gibbRate,
  market,
  trees,
  acres,
}) => {
  const columns = [
    {
      header: 'Gibb Rate',
      value: gibbRate ? GibbRate[gibbRate] : '-',
    },
    {
      header: 'Market',
      value: market || '-',
    },
    {
      header: 'Trees',
      value: trees || '-',
    },
    {
      header: 'Acres',
      value: acres || '-',
    },
  ];

  return (
    <table style={{ border, width, marginTop: '5px' }}>
      <thead>
        <tr>
          {columns.map(column => (
            <th key={`${column.header}${blockId}`} style={{ ...cellStyle }}>
              {column.header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          {columns.map(column => (
            <td
              key={`${column.header}${blockId}`}
              style={{ ...cellStyle, fontFamily: 'KanitLight' }}
            >
              {column.value}
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
};
