import { fetchEventSource } from '@microsoft/fetch-event-source';
import { AccordionDetails } from '@mui/material';
import { handleError } from 'common/api/handleError';
import { useStartSyncMutation } from 'common/api/syncApi';
import { MainContainerWrapper } from 'common/components/MainContainerWrapper/MainContainerWrapper';
import {
  EstimateErrorDetails,
  SyncNotification,
  SyncTaskNotification,
} from 'common/models/databaseSync/syncNotification';
import { DatabaseSyncStatus } from 'common/models/databaseSync/syncStatus';
import { environment } from 'environment';
import { useAuth } from 'features/auth/hooks';
import { FC, useEffect, useRef, useState } from 'react';
import {
  feedbackMessage,
  isSyncStart,
  updateCurrentTasks,
} from '../utils/sync/functions';
import { taskNames } from '../utils/sync/taskNames';
import { EstimateErrorTable } from './EstimateErrorTable';
import {
  AccordionHeader,
  ErrorAccordion,
  ErrorIcon,
  SpokaneWrapper,
  StatusLabel,
  SyncButton,
  SyncProgressBar,
} from './SpokaneSyncStyles';
import { TaskResultsTable } from './TaskResultsTable';

/**
 * Allows the user to start the Spokane synchronization process and displays
 * information sent by the server as the process progresses and completes.
 */
export const SpokaneSync: FC = () => {
  const [startSync, { isLoading: startingLocally, error: startSyncError }] =
    useStartSyncMutation();
  const [syncNotification, setSyncNotification] = useState<SyncNotification>({
    status: DatabaseSyncStatus.None,
  });
  const [completionNotifications, setCompletionNotifications] = useState<
    SyncTaskNotification[]
  >([]);
  const [syncStatusError, setSyncStatusError] = useState(false);
  const [syncErrorMessage, setSyncErrorMessage] = useState<string | undefined>(
    '',
  );
  const [waitingToStart, setWaitingToStart] = useState(false);
  const [joiningStoppedSync, setJoiningStoppedSync] = useState(false);
  const [syncProgress, setSyncProgress] = useState(0);
  const [detailsExpanded, setDetailsExpanded] = useState(false);
  const [syncEstimateErrors, setEstimateErrors] =
    useState<EstimateErrorDetails[]>();
  const syncInProgress = syncNotification.status === DatabaseSyncStatus.Pending;
  const auth = useAuth();
  const isOpening = useRef(false);
  const taskDetailsPresent =
    !!completionNotifications.length || !!syncEstimateErrors?.length;
  const showDetails =
    (syncNotification.status === DatabaseSyncStatus.Failed ||
      syncNotification.status === DatabaseSyncStatus.PartiallyComplete) &&
    (taskDetailsPresent || !!syncErrorMessage);

  // This effect sets a waiting bar for the times when starting the sync is slow.
  // We do this after a delay to avoid bad flicker on quick starts.
  useEffect(() => {
    let cleanup: (() => void) | undefined;

    if (startingLocally) {
      const timeout = setTimeout(() => setWaitingToStart(true), 1000);
      cleanup = () => clearTimeout(timeout);
    } else {
      setWaitingToStart(false);
    }

    return cleanup;
  }, [startingLocally]);

  useEffect(() => {
    if (startSyncError) {
      handleError(startSyncError, 'Error starting sync, please try again.');
    }
  }, [startSyncError]);

  useEffect(() => {
    const controller = new AbortController();
    const initEventSource = async () => {
      await fetchEventSource(
        new URL('data-sync/status', environment.apiRoute).toString(),
        {
          headers: { authorization: `Bearer ${auth.token}` },
          signal: controller.signal,
          onopen: async res => {
            isOpening.current = true;
            setSyncStatusError(!res.ok);
          },
          onerror: () => setSyncStatusError(true),
          onmessage: msg => {
            const parsedMsg = JSON.parse(msg.data) as SyncNotification;
            const starting = isSyncStart(parsedMsg);
            const joiningPendingSync =
              isOpening.current &&
              parsedMsg.status === DatabaseSyncStatus.Pending;
            const joiningStoppedSync =
              isOpening.current &&
              parsedMsg.status !== DatabaseSyncStatus.Pending;
            isOpening.current = false;

            setJoiningStoppedSync(joiningStoppedSync);
            setSyncNotification(parsedMsg);
            setCompletionNotifications(prev => {
              return starting
                ? []
                : updateCurrentTasks(joiningPendingSync ? [] : prev, parsedMsg);
            });
            setSyncErrorMessage(parsedMsg.error);
            setEstimateErrors(parsedMsg.estimateErrors);
          },
        },
      );
    };

    initEventSource();

    return () => controller.abort();
  }, [auth]);

  useEffect(() => {
    const taskFactor = 100 / (Object.keys(taskNames).length - 1);

    // When entering the view with no sync ongoing, we don't want to move the
    // progress bar to avoid the false impression of something happening.
    if (!joiningStoppedSync) {
      setSyncProgress(() =>
        isSyncStart(syncNotification)
          ? 0
          : completionNotifications.length * taskFactor,
      );
    }
  }, [joiningStoppedSync, syncNotification, completionNotifications]);

  return (
    <MainContainerWrapper title='Spokane Sync' maxWidth='1000px'>
      <SpokaneWrapper>
        {syncStatusError ? (
          <StatusLabel isError>Error getting sync status.</StatusLabel>
        ) : (
          <>
            <StatusLabel
              isError={syncNotification.status === DatabaseSyncStatus.Failed}
            >
              {feedbackMessage(syncNotification)}
            </StatusLabel>
            <SyncButton
              type='button'
              disabled={startingLocally || syncInProgress}
              onClick={() => {
                // Note that resetting progress here only applies to the client
                // that's initiating the sync - other clients are reset by events
                // received from the server.
                setSyncProgress(0);
                setCompletionNotifications([]);
                startSync();
              }}
            >
              Sync Now
            </SyncButton>
            <SyncProgressBar
              variant={waitingToStart ? 'indeterminate' : 'determinate'}
              value={syncProgress}
            />
            {showDetails && (
              <ErrorAccordion expanded={detailsExpanded}>
                <AccordionHeader
                  onClick={() => setDetailsExpanded(!detailsExpanded)}
                >
                  <ErrorIcon />
                  Details
                </AccordionHeader>
                <AccordionDetails>
                  {!!completionNotifications.length && (
                    <TaskResultsTable taskResults={completionNotifications} />
                  )}
                  {!!syncEstimateErrors?.length && (
                    <EstimateErrorTable estimateErrors={syncEstimateErrors} />
                  )}
                  {syncErrorMessage && (
                    <>
                      {taskDetailsPresent && <hr />}
                      <p>
                        {taskDetailsPresent ? 'Additionally, there ' : 'There '}
                        was a general problem during the synchronization
                        process. The details below can help technical support
                        resolve the issue:
                      </p>
                      <p>{syncErrorMessage}</p>
                    </>
                  )}
                </AccordionDetails>
              </ErrorAccordion>
            )}
          </>
        )}
      </SpokaneWrapper>
    </MainContainerWrapper>
  );
};
