import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { useRbac } from 'features/rbac';
import { linkLabels, links } from '../navbarLinks';

export type NavLinkConfig = {
  icon?:
    | OverridableComponent<SvgIconTypeMap>
    | React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  label: string;
  path: string;
  subLinks?: NavLinkConfig[];
};

export const useNavLinks = (): NavLinkConfig[] => {
  const { userHasPermission } = useRbac();
  const navLinks: NavLinkConfig[] = [];

  links.forEach(link => {
    switch (link.label) {
      case linkLabels.users:
        if (userHasPermission('user:read')) {
          navLinks.push(link);
        }
        break;
      case linkLabels.growers:
        if (userHasPermission('growers:read')) {
          navLinks.push(link);
        }
        break;
      case linkLabels.evalEst:
        if (userHasPermission('evalEst:read')) {
          navLinks.push(link);
        }
        break;
      case linkLabels.packPlan:
        if (userHasPermission('packPlan:read')) {
          navLinks.push(link);
        }
        break;
      case linkLabels.pickSchedule:
        if (userHasPermission('pickSchedule:read')) {
          navLinks.push(link);
        }
        break;
      case linkLabels.payroll:
        if (userHasPermission('payroll:read')) {
          navLinks.push(link);
        }
        break;
      case linkLabels.settings:
        if (userHasPermission('settings:read')) {
          navLinks.push(link);
        }
        break;
      default:
        break;
    }
  });

  return navLinks;
};
