import MenuIcon from '@mui/icons-material/Menu';
import UserCard from 'common/components/UserCard';
import { useNavLinks } from 'features/navbar/hooks/useNavLinks';
import { NavMenu } from 'features/navbar/NavMenu';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Constants } from 'utils/constants';
import logo from '../../../assets/img/Transp_logo.png';
import Header from '../../../common/components/Header';
import { SeasonSelector } from './SeasonSelector/SeasonSelector';
import {
  ContentContainer,
  FixedTopBar,
  LogoBar,
  logoIconStyle,
  MenuBar,
  menuIconStyle,
  MobileLinks,
} from './styles';

const MobileNav = ({
  isLoading,
  showSeasonSelector,
  children,
}: {
  isLoading: boolean;
  showSeasonSelector: boolean;
  children: React.ReactNode;
}): JSX.Element => {
  const navLinks = useNavLinks();
  const { pathname } = useLocation();
  const link =
    navLinks[navLinks.findIndex(link => pathname.startsWith(link.path))];

  const [expanded, setExpanded] = useState(true);
  const closeNav = () => setExpanded(false);
  const toggleMenu = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <FixedTopBar>
        <MenuBar $showSeasonSelector={showSeasonSelector}>
          <MenuIcon style={menuIconStyle} onClick={toggleMenu} />
          <LogoBar>
            {expanded ? (
              <>
                <img src={logo} alt='logo' width='50px' />
                <Header variant='h4'>{Constants.legalCompanyName}</Header>
              </>
            ) : (
              <>
                {link?.icon && <link.icon style={logoIconStyle} />}
                <Header variant='h4'>{link?.label}</Header>
                {showSeasonSelector && <SeasonSelector isLoading={isLoading} />}
              </>
            )}
          </LogoBar>
        </MenuBar>
      </FixedTopBar>
      <MobileLinks style={{ display: expanded ? 'flex' : 'none' }}>
        <UserCard />
        <NavMenu closeMobileNav={closeNav} />
      </MobileLinks>
      <ContentContainer style={{ display: expanded ? 'none' : 'flex' }}>
        {children}
      </ContentContainer>
    </>
  );
};

export default MobileNav;
