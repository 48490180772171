import { RoleType, User } from 'common/models';
import { DayInfo } from './dateTimeHelpers';
import { Constants } from './constants';

export const determinePathOnLogin = (role: RoleType | undefined): string => {
  switch (role) {
    case RoleType.Admin:
    case RoleType.Analyst:
      return Constants.routes.GROWERS_LIST;
    case RoleType.FieldRep:
      return Constants.routes.EVAL_EST_MAIN;
    case RoleType.HarvestManager:
    case RoleType.HarvestCo:
      return Constants.routes.PICK_SCHEDULE;
    default:
      return '/';
  }
};

export const abbreviateRole = (role: RoleType): string => {
  switch (role) {
    case RoleType.Admin:
      return 'System Admin';
    case RoleType.FieldRep:
      return 'Field Rep.';
    case RoleType.HarvestCo:
      return 'Harvest Coord.';
    case RoleType.HarvestManager:
      return 'Harvest Mgr.';
    default:
      return role;
  }
};

export const determineRoleDotColor = (role: RoleType): string => {
  switch (role) {
    case RoleType.Admin:
      return 'navy-blue';
    case RoleType.Analyst:
      return 'light-blue';
    case RoleType.FieldRep:
      return 'orange';
    case RoleType.HarvestCo:
      return 'green';
    case RoleType.HarvestManager:
      return 'dark-orange';
    default:
      return '';
  }
};

export const getUserFullName = (user: User): string =>
  `${user.firstName} ${user.lastName}`;

export function stringSortAscending(a: string, b: string): number {
  const first = a.toLowerCase();
  const second = b.toLowerCase();
  if (first < second) return -1;
  if (first > second) return 1;
  return 0;
}

/**
 * Returns a given day's date in the 'DDMMYYY' format preceded by
 * the 'WE' string (Ex: 'WE01072024' would be the string returned for
 * the first Sunday of 2024).
 */
export const generateWeekEndString = (lastDayInfo: DayInfo) => {
  const lastDayMonth =
    lastDayInfo.month.toString().length === 1
      ? `0${lastDayInfo.month}`
      : lastDayInfo.month;
  const lastDayDate =
    lastDayInfo.day.toString().length === 1
      ? `0${lastDayInfo.day}`
      : lastDayInfo.day;
  const lastDayYear = lastDayInfo.year;

  return `WE${lastDayMonth}${lastDayDate}${lastDayYear}`;
};
