import { MainContainerWrapper } from 'common/components/MainContainerWrapper/MainContainerWrapper';
import { TimeframeSwitcher } from 'common/components/TimeframeSwitcher/TimeframeSwitcher';
import { FC } from 'react';

export const Surcharges: FC = () => {
  return (
    <MainContainerWrapper title='Fuel Surcharges'>
      <TimeframeSwitcher identifier='fuel-surcharges' timeframe='month' />
    </MainContainerWrapper>
  );
};
