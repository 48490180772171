import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { darkNavy, orange, white } from 'common/styles/colors';
import { FC, MouseEvent, MouseEventHandler, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { NavLinkConfig } from '../hooks/useNavLinks';
import { LinkContainer, MainLink, SubLink, SubLinksContainer } from './styles';

/**
  A component that can be used as a navigation link on its own, or as
  the parent of a set of sub-links.
*/
export const CustomNavLink: FC<{
  link: NavLinkConfig;
  closeMobileNav?: MouseEventHandler<HTMLElement>;
}> = ({ link, closeMobileNav }) => {
  const { pathname } = useLocation();
  const [showSubLinks, setShowSubLinks] = useState(false);
  const isActiveLink = pathname.startsWith(link.path);

  const onMainLinkClick = (
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
  ) => {
    if (!showSubLinks) {
      setShowSubLinks(true);
    }
    if (closeMobileNav) {
      closeMobileNav(e);
    }
  };

  return (
    <>
      <LinkContainer $isActive={isActiveLink} onClick={onMainLinkClick}>
        <MainLink to={link.path}>
          {link.icon && (
            <link.icon style={{ fill: isActiveLink ? orange : white }} />
          )}
          <span data-testid='link-title'>{link.label}</span>
        </MainLink>
        {link.subLinks && (
          <button
            type='button'
            onClick={e => {
              e.stopPropagation();
              setShowSubLinks(!showSubLinks);
            }}
          >
            {showSubLinks ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </button>
        )}
      </LinkContainer>
      {link.subLinks && showSubLinks && (
        <SubLinksContainer onClick={closeMobileNav}>
          {link.subLinks.map(subLink => {
            const isActiveSubLink = pathname === subLink.path;

            return (
              <SubLink
                key={subLink.label}
                to={subLink.path}
                $isActive={isActiveSubLink}
              >
                {subLink.icon && (
                  <subLink.icon
                    style={{ fill: isActiveSubLink ? orange : darkNavy }}
                  />
                )}
                <span>{subLink.label}</span>
              </SubLink>
            );
          })}
        </SubLinksContainer>
      )}
    </>
  );
};
