import { FetchArgs } from '@reduxjs/toolkit/dist/query';
import { SavedFilterOptions, SavedSortOptions } from 'common/hooks';

export const FilterValueSeparator = '|';

interface SortParams {
  /**
   * A list of specially-handled sorting options.
   *
   * @remarks
   *
   * This is a list of sort options that have to be formatted in a special way
   * before sending to the backend, and that are also handled in a special way
   * by the backend. See {@link withSortsAndFilters} for more information.
   */
  embeddedSorts?: SavedSortOptions[];
}

interface FilterParams {
  filters: SavedFilterOptions[];
}

export type SortFilterParams = SortParams & FilterParams;

/**
 * Builds the arguments object for a query, ensuring that sorts and filters are
 * serialized in the format expected by the backend. **Note that sorting is not
 * fully supported yet, see remarks.**
 *
 * @param params The object whose properties will be used as query parameters,
 *  including, at a minimum, the filters to process.
 * @param baseUrl The base URL to which parameters will be appended.
 *
 * @remarks
 *
 * ### Notes on sorting:
 *
 * There are two kinds of sorting: regular and "embedded". Most of the backend
 * endpoints expect regular sorting, which is a simple list of sort keys and the
 * sort order.
 *
 * Embedded sorting is a special case where an additional value to sort by can be
 * specified, effectively embedded in the sort key. This has to be enabled per
 * route on the backend, on a case-by-case basis.
 *
 * Despite its commonness, **regular sorting is not currently supported** by this
 * function. This is because, currently, the only sorting needed by the app is
 * embedded sorting.
 *
 * In the future, if regular sorting is needed, a `sorts` property can be added
 * to {@link SortParams}, and this function will have to be updated to handle it.
 */
export function withSortsAndFilters<T extends SortFilterParams>(
  { embeddedSorts, filters, ...otherParams }: T,
  baseUrl: string,
): FetchArgs {
  const serializedEmbeddedSorts = (embeddedSorts || [])
    .map(sort => {
      const encodedKeys = sort.options.map(
        /*
        Because the server-side PSF package expects a limited sort type,
        we are using a (hacky) work-around; we pass the sort's 'category'
        option on the key ({ '[category].[option]': 'DESC' }).
        */
        opt => `${sort.category}.${encodeURIComponent(opt.trim())}`,
      );

      return encodedKeys.join();
    })
    .join(',');

  const serializedFilters = (filters || [])
    .map(filter => {
      const encodedOptions = filter.options.map(opt =>
        encodeURIComponent(opt.trim()),
      );
      return `${filter.category}:${encodedOptions.join(FilterValueSeparator)}`;
    })
    .join(',');

  let queryString = '';
  const hasSorts = serializedEmbeddedSorts.length > 0;
  const hasFilters = serializedFilters.length > 0;

  if (hasSorts && !hasFilters) {
    queryString = `?sort=${serializedEmbeddedSorts}`;
  }

  if (!hasSorts && hasFilters) {
    queryString = `?filter=${serializedFilters}`;
  }

  if (hasSorts && hasFilters) {
    queryString = `?sort=${serializedEmbeddedSorts}&filter=${serializedFilters}`;
  }

  return {
    url: `${baseUrl}${queryString}`,
    ...(Object.keys(otherParams).length > 0 && { params: otherParams }),
  };
}
