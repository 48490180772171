import { SizeEstimate } from 'common/models/growerBlock/sizeEstimate';
import { FC } from 'react';
import { AdditionalDetailsTable, ExtraDetails } from './AdditionalDetailsTable';
import { SizeEstimateTable } from './SizeEstimateTable';
import { CollapsedRowContainer } from './styles';

export const CollapsibleRow: FC<{
  sizeEstimates: SizeEstimate[];
  details: ExtraDetails;
}> = ({ sizeEstimates, details }) => {
  const estTableWidth = 500;
  const detailsTableWidth = 400;

  return (
    <CollapsedRowContainer
      maxWidth={`${estTableWidth + detailsTableWidth + 30}px`}
    >
      {sizeEstimates.length > 0 ? (
        <SizeEstimateTable
          width={`${estTableWidth}px`}
          estimates={sizeEstimates}
        />
      ) : (
        <p>No size estimates yet.</p>
      )}
      <AdditionalDetailsTable width={`${detailsTableWidth}px`} {...details} />
    </CollapsedRowContainer>
  );
};
