import { useGetAppHealthQuery } from 'common/api/healthCheckApi';
import { MainContainerWrapper } from 'common/components/MainContainerWrapper/MainContainerWrapper';
import { mobile } from 'common/styles/breakpoints';
import { Title } from 'common/styles/form';
import { environment } from 'environment';
import { EnvironmentConfiguration } from 'environment/types';
import styled from 'styled-components';
import { Constants } from '../../utils/constants';
import { DeveloperTools } from './components/DeveloperTools';

const { appName, version } = Constants;

const Subtitle = styled(Title)`
  font-size: 1rem;
  margin: 0px;
`;

const SettingsRow = styled.div`
  display: grid;
  grid-template-columns: 30% 70%;

  @media (max-width: ${mobile}) {
    grid-template-columns: 40% 60%;
  }
`;

const SettingsView = (): JSX.Element => {
  const { data: healthCheck } = useGetAppHealthQuery();

  return (
    <MainContainerWrapper title='Settings' maxWidth='800px'>
      <Title style={{ marginTop: '10px', fontSize: '1.5rem' }}>{appName}</Title>
      <SettingsRow>
        <Subtitle>Client Version</Subtitle>
        <span style={{ fontFamily: 'KanitBold' }}>{version}</span>
        <Subtitle>Server Version</Subtitle>
        <span style={{ fontFamily: 'KanitBold' }}>
          {healthCheck?.version ?? '-'}
        </span>
      </SettingsRow>
      <hr />
      {(environment.name === EnvironmentConfiguration.Development ||
        environment.name === EnvironmentConfiguration.Testing) && (
        <SettingsRow>
          <Subtitle>Developer Tools</Subtitle>
          <DeveloperTools />
        </SettingsRow>
      )}
    </MainContainerWrapper>
  );
};

export default SettingsView;
